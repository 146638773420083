<template>
  <div class="gs-relative">
    <button
      class="gs-absolute gs-inset-y-0 gs-left-0 gs-flex gs-items-center gs-pl-5 hover:gs-text-gray-500"
      @click="getUserPosition"
    >
      <img
        src="../images/house-icon.svg"
        alt="house"
      >
    </button>

    <GMapAutocomplete
      placeholder="All locations..."
      class="gs-w-full gs-pl-12 gs-h-14 gs-rounded-full gs-border gs-border-beige-500 focus:gs-outline-none focus:gs-border-stone-500"
      :options="placeOptions"
      :value="location"
      @place_changed="setLocationFromPlace"
    />

    <div class="gs-absolute gs-inset-y-1 gs-right-3 gs-flex">
      <button
        v-show="location != ''"
        class="gs-px-4 gs-text-stone-300 hover:gs-text-gray-500 gs-text-xl gs-bg-white"
        @click="clearLocation"
      >
        &times;
      </button>
      <select
        :value="radius"
        class="gs-h-12 form-select gs-rounded-r-full gs-border-0 gs-bg-white"
        @change="changeRadius($event)"
      >
<!--        <option value="0">
          +0 kms
        </option>-->
        <option value="5000">
          5&lt; kms
        </option>
        <option value="10000">
          10&lt; kms
        </option>
        <option value="15000">
          15&lt; kms
        </option>
        <option value="20000">
          20&lt; kms
        </option>
        <option value="30000">
          30&lt; kms
        </option>
        <option value="50000">
          50&lt; kms
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import * as region from '../utils/region';

export default {
  props: {
    location: { type: String, default: '' },
    lat: { type: Number, default: null },
    lng: { type: Number, default: null },
    radius: { type: Number, default: 50000 },
  },
  emits: ['update:location', 'update:lat', 'update:lng', 'update:radius', 'change'],
  data() {
    return {
      placeOptions: {
        componentRestrictions: {
          country: [region.region()],
        },
        fields: ['geometry.location', 'name', 'adr_address'],
        strictBounds: true,
        types: ['geocode'],
      },
    };
  },
  mounted() {
    console.log('this.radius');
    console.log(this.radius);
  },
  methods: {
      changeRadius(event) {
          this.$emit('update:radius', Number(event.target.value));
          this.$emit('change');
      },
    clearLocation() {
      this.updateLocation(
        '',
        null,
        null,
      );
    },
    getUserPosition() {
      navigator.geolocation.getCurrentPosition(this.setLocationFromUserPosition.bind(this));
    },
    setLocationFromUserPosition(position) {
      this.updateLocation(
        'My Current Location',
        position.coords.latitude,
        position.coords.longitude,
      );
    },
    setLocationFromPlace(place) {
      try {
        this.updateLocation(
          place.adr_address.replace(/<[^>]*>?/gm, ''),
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        );
      } catch (error) {
        this.updateLocation(
          place.name,
          null,
          null,
        );
      }
    },
    updateLocation(location, lat, lng) {
      this.$emit('update:location', location);
      this.$emit('update:lat', lat);
      this.$emit('update:lng', lng);
      this.$emit('change');
    },
  },
};
</script>

<style>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer components {
    .pac-container{
      box-shadow: none;
      @apply gs-border-none gs-p-5 gs-mt-1 gs-drop-shadow-md gs-rounded-md gs-bg-white gs-space-y-3;
    }

    .pac-icon {
      @apply gs-mt-1;
    }

    .pac-item {
      @apply gs-px-5 gs-py-4 gs-border gs-rounded-sm gs-border-beige-500 gs-cursor-pointer gs-text-base;
    }

    .pac-item-query {
      @apply gs-text-base;

    }

    .pac-logo {
      @apply gs-grayscale;
    }

    .pac-logo:after {
        margin-top: 1rem;
    }
  }
</style>
