<template>
  <section class="gs-bg-beige-300">
    <div class="gs-px-4 gs-py-8">
      <div class="gs-grid gs-grid-1 lg:gs-grid-cols-12 gs-gap-2">
        <div class="gs-relative lg:gs-col-span-6 2xl:gs-col-span-7">
          <button
            class="gs-absolute gs-inset-y-0 gs-left-0 gs-flex gs-items-center gs-pl-5"
            disabled
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 3.875V2.375H6V3.875H9ZM1.5 5.375V13.625H13.5V5.375H1.5ZM13.5 3.875C14.3325 3.875 15 4.5425 15 5.375V13.625C15 14.4575 14.3325 15.125 13.5 15.125H1.5C0.6675 15.125 0 14.4575 0 13.625L0.00749999 5.375C0.00749999 4.5425 0.6675 3.875 1.5 3.875H4.5V2.375C4.5 1.5425 5.1675 0.875 6 0.875H9C9.8325 0.875 10.5 1.5425 10.5 2.375V3.875H13.5Z"
                fill="#474747"
              />
            </svg>
          </button>

          <input
            v-model="newQuery.q"
            class="gs-w-full gs-pl-12 gs-h-14 gs-rounded-full gs-border gs-border-beige-500 focus:gs-outline-none focus:gs-border-stone-500"
            type="text"
            placeholder="All jobs..."
            @keyup.enter="$emit('newSearch', newQuery)"
          >

          <button
            v-show="newQuery.q != ''"
            class="gs-absolute gs-inset-y-0 gs-right-2 gs-rounded-xl gs-px-5 gs-text-stone-300 hover:gs-text-stone-500 gs-text-xl gs-bg-white gs-my-1"
            @click="clearQuery"
          >
            &times;
          </button>
        </div>

        <search-location-filter
          v-model:location="newQuery.location"
          v-model:lat="newQuery.lat"
          v-model:lng="newQuery.lng"
          v-model:radius="newQuery.radius"
          class="gs-hidden lg:gs-block lg:gs-col-span-4"
          @change="$emit('newSearch', newQuery)"
        />

        <div class="lg:gs-col-span-2 2xl:gs-col-span-1">
          <primary-button
            class="search-btn primary-btn gs-font-medium gs-w-full gs-h-14"
            @click="$emit('newSearch', query)"
          >
            Search
            <font-awesome-icon
              class="gs-ml-2 gs-text-xs"
              :icon="['fas', 'magnifying-glass']"
            />
          </primary-button>
        </div>

        <div class="gs-col-span-1 lg:gs-hidden">
          <button
            class="gs-w-full gs-h-14 gs-px-6 gs-py-2 gs-inline-flex gs-items-center gs-justify-between gs-rounded-full gs-border gs-border-beige-500 hover:gs-border-stone-500"
            @click="toggleFilterModal()"
          >
            <span>Filters</span>
            <font-awesome-icon
              class="gs-ml-2 gs-text-xs"
              :icon="['fas', 'chevron-down']"
            />
          </button>
        </div>
      </div>
      <div class="gs-hidden lg:gs-flex gs-justify-start gs-flex-wrap gs-gap-2 gs-space-x-2 gs-mt-2">
        <search-remote-status-filter
          v-model="newQuery.remote_status"
          :facet="facets.remote_status"
          @update:model-value="$emit('newSearch', newQuery)"
        />

        <search-experience-filter
          v-model="newQuery.experience_ids"
          :facet="facets.experience_id"
          @update:model-value="$emit('newSearch', newQuery)"
        />

        <search-salary-filter
          v-model:salary="newQuery.min_salary"
          v-model:freq="newQuery.min_salary_freq"
          @update:freq="$emit('newSearch', newQuery)"
        />

        <search-industry-filter
          v-model="newQuery.industry_ids"
          :facet="facets.industry_ids"
          @update:model-value="$emit('newSearch', newQuery)"
        />

        <search-job-type-filter
          v-model="newQuery.job_type_ids"
          :facet="facets.job_type_ids"
          @update:model-value="$emit('newSearch', newQuery)"
        />

        <search-size-filter
          v-model="newQuery.company_size_id"
          :facet="facets.company_size_id"
          @update:model-value="$emit('newSearch', newQuery)"
        />

        <search-benefit-filter
          v-model="newQuery.benefit_ids"
          :facet="facets.benefit_ids"
          @update:model-value="$emit('newSearch', newQuery)"
        />

<!--          <search-target-country-filter
              v-model="newQuery.target_country_ids"
              :facet="facets.target_country_ids"
              @update:model-value="$emit('newSearch', newQuery)"
          />-->

          <search-role-filter
              v-model="newQuery.role"
              :facet="facets.role"
              @update:model-value="$emit('newSearch', newQuery)"
          />

          <search-languages-filter
              v-model="newQuery.language_types"
              :facet="facets.language_types"
              @update:model-value="$emit('newSearch', newQuery)"
          />

        <primary-button
          class="gs-text-sm primary-btn btn-c"
          @click="clearFilters"
        >
          Reset all
        </primary-button>
      </div>
    </div>
    <Transition
      enter-active-class="gs-transition gs-ease-out gs-duration-100"
      enter-from-class="gs-transform gs-opacity-0 gs-scale-95"
      enter-to-class="gs-transform gs-opacity-100 gs-scale-100"
      leave-active-class="gs-transition gs-ease-in gs-duration-75"
      leave-from-class="gs-transform gs-opacity-100 gs-scale-100"
      leave-to-class="gs-transform gs-opacity-0 gs-scale-95"
    >
      <div
        v-show="showFiltersModal"
        class="gs-absolute gs-top-2 gs-z-20 gs-w-full gs-shadow-[0_-3px_5px_3px_rgba(0,0,0,0.1)] gs-bg-beige-200 gs-rounded-3xl gs-border gs-border-beige-400 lg:gs-hidden"
        style="position: relative;z-index: 9999;"
      >
        <div
          class="gs-p-6 gs-space-y-3"
        >
          <div class="gs-flex gs-items-center gs-justify-between gs-text-xl gs-font-medium">
            <span>Filters</span>
            <button
              class="gs-text-4xl"
              @click="toggleFilterModal()"
            >
              &times;
            </button>
          </div>

          <search-location-filter
            v-model:location="newQuery.location"
            v-model:lat="newQuery.lat"
            v-model:lng="newQuery.lng"
            v-model:radius="newQuery.radius"
            @change="$emit('newSearch', newQuery)"
          />

          <search-remote-status-filter
            v-model="newQuery.remote_status"
            :facet="facets.remote_status"
            @update:model-value="$emit('newSearch', newQuery)"
          />

          <search-experience-filter
            v-model="newQuery.experience_ids"
            :facet="facets.experience_id"
            @update:model-value="$emit('newSearch', newQuery)"
          />

          <search-salary-filter
            v-model:salary="newQuery.min_salary"
            v-model:freq="newQuery.min_salary_freq"
            @update:freq="$emit('newSearch', newQuery)"
          />

          <search-industry-filter
            v-model="newQuery.industry_ids"
            :facet="facets.industry_ids"
            @update:model-value="$emit('newSearch', newQuery)"
          />

          <search-job-type-filter
            v-model="newQuery.job_type_ids"
            :facet="facets.job_type_ids"
            @update:model-value="$emit('newSearch', newQuery)"
          />

          <search-size-filter
            v-model="newQuery.company_size_id"
            :facet="facets.company_size_id"
            @update:model-value="$emit('newSearch', newQuery)"
          />

          <search-benefit-filter
            v-model="newQuery.benefit_ids"
            :facet="facets.benefit_ids"
            @update:model-value="$emit('newSearch', newQuery)"
          />

<!--            <search-target-country-filter
                v-model="newQuery.target_country_ids"
                :facet="facets.target_country_ids"
                @update:model-value="$emit('newSearch', newQuery)"
            />-->

            <search-languages-filter
                v-model="newQuery.language_types"
                :facet="facets.language_types"
                @update:model-value="$emit('newSearch', newQuery)"
            />

            <search-role-filter
                v-model="newQuery.role"
                :facet="facets.role"
                @update:model-value="$emit('newSearch', newQuery)"
            />
        </div>
        <div
          class="gs-w-full gs-fixed gs-bottom-0 gs-left-0 gs-p-6 gs-bg-beige-200 gs-border gs-border-beige-500 gs-shadow-[0_-3px_5px_3px_rgba(0,0,0,0.1)]"
        >
          <div
            class="gs-flex gs-justify-between gs-space-x-4"
          >
            <button
              class="btn-b gs-w-full gs-h-14 gs-px-4 gs-py-2 gs-rounded-full primary-btn gs-border gs-border-beige-500"
              @click="clearFilters(); toggleFilterModal();"
            >
              Reset all
            </button>
            <primary-button
              class="gs-w-full gs-h-14 btn-a"
              @click="toggleFilterModal()"
            >
              Search
            </primary-button>
          </div>
        </div>
      </div>
    </Transition>
  </section>
</template>

<script>
import PrimaryButton from './PrimaryButton.vue';
import SearchBenefitFilter from './SearchBenefitFilter.vue';
import SearchExperienceFilter from './SearchExperienceFilter.vue';
import SearchIndustryFilter from './SearchIndustryFilter.vue';
import SearchJobTypeFilter from './SearchJobTypeFilter.vue';
import SearchLanguagesFilter from './SearchLanguagesFilter.vue';
import SearchLocationFilter from './SearchLocationFilter.vue';
import SearchRemoteStatusFilter from './SearchRemoteStatusFilter.vue';
import SearchSalaryFilter from './SearchSalaryFilter.vue';
import SearchSizeFilter from './SearchSizeFilter.vue';
import SearchRoleFilter from './SearchRoleFilter.vue';
import { nullQuery, nullFilters } from '../utils/null-objects';
import SearchTargetCountryFilter from "./SearchTargetCountryFilter.vue";

export default {
  components: {
      SearchTargetCountryFilter,
    PrimaryButton,
    SearchBenefitFilter,
    SearchExperienceFilter,
    SearchIndustryFilter,
    SearchJobTypeFilter,
    SearchLocationFilter,
      SearchRemoteStatusFilter,
      SearchLanguagesFilter,
    SearchSalaryFilter,
    SearchSizeFilter,
      SearchRoleFilter,
  },
  props: {
    query: { type: Object, default: null },
    facets: { type: Object, default: null },
    showFiltersModal: { type: Boolean, default: false },
  },
  emits: ['newSearch', 'toggleFilterModal'],
  data() {
    return {
      newQuery: this.query,
    };
  },
  mounted() {
      console.log(this.query);
  },
  methods: {
    clearFilters() {
      this.newQuery = { ...this.newQuery, ...nullFilters };
      // this.newQuery.company_size_id = this.facets.company_size_id;
      this.$emit('newSearch', this.newQuery);
    },
    clearQuery() {
      this.newQuery.q = '';
      this.$emit('newSearch', this.newQuery);
    },
    toggleFilterModal() {
      this.$emit('toggleFilterModal');
    },
  },
};
</script>

<style scoped>

  .search-btn {padding: 0.25rem 0;}

  .primary-btn {
    background-color: #0A2430;
    color: #F9F7F4;
  }

</style>
