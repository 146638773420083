<template>
  <div class="overlay">
    <div class="dialog">
      <div class="content">
        <div class="dialog-header square">
          <img
            src="../images/orange-pink-gradient-success.svg"
            alt="alert"
            width="164"
            height="157"
            class="img-fluid"
          >
        </div>

        <div class="dialog-body">
          <h3 class="title">
            Success
          </h3>

          <p class="description">
            You can update your saved searches <br> at any time.
          </p>
        </div>

        <div class="dialog-footer">
          <button
            class="continue"
            type="button"
            @click="$emit('toggleVisibility')"
          >
            Continue your job hunt
          </button>

          <button
            id="view-searches"
            @click="viewSearches()"
          >
            View your saved searches
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
  },
  emits: ['toggleVisibility'],
  data() {
    return {
    };
  },
  methods: {
    viewSearches() {
        window.location.href = `${process.env.PORTAL_DOMAIN}/candidate/alert-subscriptions/saved-searches`;
    },
  },
};

</script>

<style scoped>
.overlay {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
}
.dialog {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.3rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  max-width: 100%;
  width: 31rem;
}
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
}
.dialog-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem 1rem;
}

.dialog-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-header.square {
  background-image: url('../images/mask-square-background.png');
  background-size: cover;
}

.dialog-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 0.75rem 0.75rem 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog-footer > * {margin: 0.25rem;}

.continue {
  font-size: 1.125rem;
  font-weight: 500;
  background-color: #0A2430;
  color: #FFFFFF;
  padding: 0.65rem 2rem;
}

#view-searches {
  font-size: 0.875rem;
  font-weight: 400;
  background-color: transparent;
  color: #A0A09E;
  padding: 0.375rem 0.75rem;
}

.continue, #view-searches {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 25px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: 'DM Sans', sans-serif;
}

.title {
  font-weight: 700;
  font-family: 'Athletics', sans-serif;
  font-size: calc(1.3rem + 0.6vw);
  line-height: 1.2;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: #0A2430;
}

@media (min-width: 1200px) {
  .title {
    font-size: 2.25rem;
  }
}

.description {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 0;
  color: #474747;
  font-family: 'DM Sans', sans-serif;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

</style>
