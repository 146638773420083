<template>
  <a
    class="gs-text-xl gs-text-beige-400 gs-cursor-pointer"
    :href="url"
    target="_blank"
  >
    <font-awesome-icon :icon="icon" />
  </a>
</template>

<script>
export default {
  props: {
    url: { type: String, default: '' },
    type: { type: String, default: 'link' },
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'twitter':
          return ['fab', 'twitter'];
        case 'facebook':
          return ['fab', 'facebook'];
        case 'email':
          return ['far', 'envelope'];
        default:
          return ['fas', 'link'];
      }
    },
  },
};
</script>
