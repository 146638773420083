<template>
  <div class="gs-m-6 gs-p-6 gs-max-h-full">
    <div class="gs-bg-beige-100">
        <div v-if="result.meta.has_applied" class="d-flex flex-row align-items-center gap-2 mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" width="20px"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
            <div>Applied on {{ appliedAt }}</div>
        </div>

      <div class="gs-flex gs-justify-between">
        <img
          class="gs-h-14 gs-w-14 gs-rounded-xl"
          :src="(result.logo===null)? result.company.logo : result.logo"
          :alt="result.company.name"
        >
        <search-result-actions
          v-if="!isQuickView"
          :id="result.id"
          class="gs-justify-end"
          :share="sanitiseUrl(result.links.share)"
          :apply="result.links.apply"
          :save="result.links.save"
          :is-saved="result.meta.is_saved"
          :has-applied="result.meta.has_applied"
          :application-status="result.meta.application_status"
          @toggle-saved="$emit('toggleSaved')"
        />
      </div>

      <div class="gs-mt-5">
        <h2 class="gs-text-twilight-900 gs-font-medium heading">
          {{ result.title }}
        </h2>
        <div class="gs-grid gs-grid-cols-2 gap-4 gs-text-beige-400 gs-font-medium fs-7">
            <div v-if="result.salary_display">
                {{ result.salary_display }}
            </div>
            <div>
                {{ result.location.name }}<template v-if="result.remote_status"> ({{ result.remote_status }})</template>
            </div>
            <div v-if="result.job_type && result.job_type.name">
                {{ result.job_type.name }}
            </div>
            <div v-if="result.published_at_human">
              <template v-if="result.published_at_human === 'Today' || result.published_at_human === 'Yesterday'">
                Posted {{ result.published_at_human.toLowerCase() }}
              </template>
              <template v-else>
                {{ result.published_at_human }}
              </template>
            </div>
            <div v-if="result.planned_start_date!==null">Starts {{ plannedStartDate }}</div>
            <div v-if="result.applications_close!==null">Applications close {{ closesIn }}</div>
        </div>
        <search-job-type-tag-list
          :tags="result.tags"
        />
      </div>
      <hr class="gs-border-t gs-border-beige-500 gs-mt-5">
    </div>

    <div class="gs-detail-body gs-max-h-[calc(100vh_-_26rem)] gs-overflow-y-auto gs-pr-3">
      <h3
        v-if="!result.company.is_recruiter"
        class="gs-text-twilight-900 gs-mt-14 heading"
      >
        💫 About {{ result.company.name }}
      </h3>
      <div
        v-if="!result.company.is_recruiter"
        class="gs-mt-5 gs-text-twilight-900"
        v-html="result.company.description"
      />

      <h3 class="gs-text-twilight-900 heading" style="margin-top: 70px;">
        👉 Job description
      </h3>
      <div
        class="gs-mt-5 gs-text-twilight-900 gs-prose gs-max-w-none"
        v-html="result.you_do_description"
      />
      <h3 class="gs-text-twilight-900 gs-mt-16 heading" v-if="result.benefits.length > 0">
        💰 Job Benefits
      </h3>
      <div class="gs-mt-5 gs-prose gs-text-twilight-900 gs-list-disc gs-list-inside">
        <ul>
          <li
            v-for="benefit in result.benefits"
            :key="benefit.id"
          >
            {{ benefit.name }}
          </li>
        </ul>
      </div>

      <!-- Need to know where this is meant to come from -->
      <!-- <p class="gs-mt-14">
        On the hunt for Research Executive roles or Admin jobs in London? If you are
        an organised graduate with a keen interest in Finance and FinTech careers,
        apply for this Research and Administration Assistant role in London today!
      </p>
      <p class="gs-mt-5">
        On the hunt for Research Executive roles or Admin jobs in London? If you are
        Give a Grad a Go is committed to being an equal opportunity employer. All
        qualified applicants will receive consideration regardless of age, gender,
        ethnicity, sexual orientation, faith, disability or other. We are continually
        finding ways to improve the way we work, read our Diversity and Inclusion
        promise for more information about this.
      </p> -->
      <hr class="gs-border-t gs-border-beige-500 gs-mt-5">
      <search-result-actions
        :id="result.id"
        class="gs-justify-start gs-mt-14"
        :share="sanitiseUrl(result.links.share)"
        :save="result.links.save"
        :apply="result.links.apply"
        :is-saved="result.meta.is_saved"
        :has-applied="result.meta.has_applied"
        :application-status="result.meta.application_status"
        @toggle-saved="$emit('toggleSaved')"
      />
      <search-section
        class="gs-mt-14 mb-5 gs-p-8 gs-border gs-border-beige-500 gs-bg-beige-100 gs-rounded-xl"
      >
        <search-additional-detail
          :result="result"
        />
      </search-section>
    </div>
  </div>
</template>

<script>
import SearchAdditionalDetail from './SearchAdditionalDetail.vue';
import SearchJobTypeTagList from './SearchJobTypeTagList.vue';
import SearchResultActions from './SearchResultActions.vue';
import SearchSection from './SearchSection.vue';
import { getAssetUrl } from '../utils/asset.js';
import moment from 'moment'

export default {
  components: {
    SearchAdditionalDetail,
    SearchJobTypeTagList,
    SearchResultActions,
    SearchSection,
  },
  props: {
    result: { type: Object, default: null },
    isQuickView: { type: Boolean, default: false },
  },
  emits: ['toggleSaved'],
  watch: {
    result() {
      const body = this.$el.querySelector('.gs-detail-body');

      body.scrollTo({
        top: 0,
        left: 0,
      });
    },
  },
  methods: {
    asset($url) {
      return getAssetUrl($url);
    },
    sanitiseUrl(url) {
      return url.replace(/([^:]\/)\/+/g, '$1');
    },
  },
    computed: {
      plannedStartDate() {
          return moment(new Date(this.result.planned_start_date)).format('Do MMM YYYY');
      },
        closesIn() {
          return moment().to(this.result.applications_close);
        },
        appliedAt() {
          if(this.result.meta.has_applied) {
              return moment(this.result.meta.applied_at).format("Do MMM YYYY HH:mm");
          } else {
              return '';
          }
        },
    },
};
</script>

<style scoped>

  .gs-detail-body {
    padding-bottom: 30px !important;
  }
  .gs-mt-16 {
      margin-top: 4rem !important;
  }

</style>
