<template>
  <div class="gs-flex gs-items-start gs-justify-between gs-text-beige-600 gs-font-medium gs-text-sm">
    <div class="gs-flex-1">
      {{ total }} jobs
    </div>
    <div class="gs-flex gs-justify-end gs-space-x-6">
      <button
        class="gs-flex gs-items-center gs-font-medium"
        @click="$emit('createAlert')"
      >
        <img
          src="../images/plus-icon.svg"
          alt="plus"
        >
        <span class="gs-ml-2">Create email alert</span>
      </button>

      <button
        class="gs-flex gs-items-center gs-font-medium"
        @click="$emit('showMap')"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 0L17.34 0.03L12 2.1L6 0L0.36 1.9C0.15 1.97 0 2.15 0 2.38V17.5C0 17.78 0.22 18 0.5 18L0.66 17.97L6 15.9L12 18L17.64 16.1C17.85 16.03 18 15.85 18 15.62V0.5C18 0.22 17.78 0 17.5 0ZM12 16L6 13.89V2L12 4.11V16Z"
            fill="#7A7A7A"
          />
        </svg>
        <span class="gs-ml-2">Map view</span>
      </button>

      <button
        disabled
        class="gs-font-medium"
      >
        <span class="gs-mr-2">Sort: Date added</span>
        <font-awesome-icon
          v-if="isSortDesc"
          :icon="['fas', 'arrow-down']"
          style="cursor: pointer;"
          @click="$emit('sortDir')"
        />
        <font-awesome-icon
          v-if="!isSortDesc"
          :icon="['fas', 'arrow-up']"
          style="cursor: pointer;"
          @click="$emit('sortDir')"
        />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    companyCount: {
      type: Number,
      default: 0,
    },
    isSortDesc: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['showMap', 'sortDir', 'createAlert'],
};

</script>
