<template>
  <div
    v-if="query && (showRadius || showIndustry)"
    class="gs-p-10 gs-mt-10 gs-border-t gs-border-beige-500 gs-bg-beige-100 lg:gs-border lg:gs-rounded-xl"
  >
    <div>
      <p class="gs-text-2xl gs-text-center">
        More jobs available
      </p>

      <p class="gs-mt-3 gs-text-beige-600 gs-text-center">
        Update your search criteria to include:
      </p>

      <div class="gs-mt-10 gs-flex gs-flex-inline gs-items-center gs-justify-center gs-gap-2">
        <p
          v-if="showRadius"
          class="gs-py-2 gs-text-xs gs-font-medium"
          style="cursor: pointer;"
          @click="increaseRadius()"
        >
          +{{ radiusIncrease }} kms
        </p>

        <p
          v-if="showIndustry"
          class="gs-py-2 gs-text-xs gs-font-medium"
          style="cursor: pointer;"
          @click="addIndustry()"
        >
          {{ industryDisplay }}
        </p>
      </div>

      <p class="gs-mt-10 gs-text-beige-600 gs-flex gs-flex-inline gs-items-center gs-justify-center gs-gap-2 lg:gs-hidden">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 0L17.34 0.03L12 2.1L6 0L0.36 1.9C0.15 1.97 0 2.15 0 2.38V17.5C0 17.78 0.22 18 0.5 18L0.66 17.97L6 15.9L12 18L17.64 16.1C17.85 16.03 18 15.85 18 15.62V0.5C18 0.22 17.78 0 17.5 0ZM12 16L6 13.89V2L12 4.11V16Z"
            fill="#7A7A7A"
          />
        </svg>
        <span>Maps available on desktop</span>
      </p>
    </div>
  </div>
</template>

<script>
import { store as detailStore } from '../stores/detail';

export default {
  props: {
    query: {
      type: Object,
      default: null,
      immediate: true,
      handler(val, oldVal) {
        refreshRadius();
      },
    },
  },
  emits: ['newQuery'],
  data() {
    return {
      showRadius: false,
      radiusIncrease: 0,
      showIndustry: false,
      industryDisplay: '',
      industryIdToAdd: 0,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.refreshRadius();
      this.refreshIndustry();
    },
    refreshRadius() {
      this.showRadius = false;
      if (this.query) {
        if (this.query.radius) {
          const currentRadius = this.query.radius;
          if (currentRadius < 50000) {
            if (currentRadius < 20000) {
              this.radiusIncrease = 5;
            } else if (currentRadius < 30000) {
              this.radiusIncrease = 10;
            } else {
              this.radiusIncrease = 20;
            }
            this.showRadius = true;
          }
        }
      }
    },
    increaseRadius() {
      this.query.radius = this.query.radius + (this.radiusIncrease * 1000);
      this.pushNewQuery();
    },
    refreshIndustry() {
      this.showIndustry = false;
      if (this.query) {
        if (this.query.industry_ids) {
          if (this.query.industry_ids.length > 0) {
            const unselectedIndustries = [];

            const industries = detailStore.data.filter((detail) => detail.type === 'Industry');
            for (let i = 0; i < industries.length; i++) {
              const industryId = industries[i].id;
              if (!this.query.industry_ids.includes(industryId)) {
                unselectedIndustries.push({ id: industryId, name: industries[i].name });
              }
            }

            if (unselectedIndustries.length > 0) {
              this.showIndustry = true;
              const randomSelection = Math.floor(Math.random() * unselectedIndustries.length);
              this.industryIdToAdd = unselectedIndustries[randomSelection].id;
              this.industryDisplay = unselectedIndustries[randomSelection].name;
            }
          }
        }
      }
    },
    addIndustry() {
      this.query.industry_ids.push(this.industryIdToAdd);
      this.pushNewQuery();
    },
    pushNewQuery() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.$emit('newQuery', this.query);
    },
  },
};

</script>

<style scoped>
  p:not(:last-child):not(:only-child):not(:last-of-type) {
  margin-bottom: 0 !important;
}
</style>
