<template>
  <div>
    <div
      v-show="showNonSaved"
      class="gs-space-y-2 gs-text-lg gs-p-10"
    >
      <p>
        You currently don't have any applications. Try <a
          href="/candidate/search-jobs"
          class="gs-underline"
        >searching</a> for new jobs.
      </p>
    </div>

    <div
      v-show="showList"
      class="result-container gs-flex lg:gs-pb-10 lg:gs-gap-4"
    >
      <div class="gs-basis-full lg:gs-basis-1/2">
        <search-section
          class="result-list gs-bg-beige-100 lg:gs-border lg:gs-border-beige-500 lg:gs-rounded-xl"
          :loading="loading"
        >
          <search-result
            v-for="result in results"
            :key="result.id"
            :is-active="result.id == currentResult.id"
            :result="result"
            class="first:gs-rounded-t-xl lg:last:gs-rounded-b-xl"
            @click="openResult(result)"
            @toggle-saved="fetchResults(resetCurrentResult)"
          />

          <template #placeholder>
            <search-result-placeholder
              v-for="n in 15"
              :key="n"
            />
          </template>
        </search-section>

        <search-pagination
          v-if="meta.links"
          :links="meta.links"
          :current-page="meta.current_page"
          :last-page="meta.last_page"
          class="gs-mt-10"
          @change-page="hasPageChanged = true; setPage($event)"
        />
      </div>

      <search-section
        ref="resultDetail"
        class="gs-hidden lg:gs-block gs-basis-1/2 gs-sticky gs-max-h-screen gs-top-0 gs-border gs-border-beige-500 gs-bg-white gs-rounded-xl"
        :loading="loading"
      >
        <search-result-detail
          v-if="currentResult"
          :result="currentResult"
          @toggle-saved="fetchResults(resetCurrentResult)"
        />

        <template #placeholder>
          <search-result-detail-placeholder />
        </template>
      </search-section>
    </div>
  </div>
</template>

<script>
import client from '../utils/http';
import { store as detailStore } from '../stores/detail';
import * as domHelper from '../utils/dom';
import { nullResult } from '../utils/null-objects';
import SearchPagination from './SearchPagination.vue';
import SearchResult from './SearchResult.vue';
import SearchResultPlaceholder from './SearchResultPlaceholder.vue';
import SearchResultDetail from './SearchResultDetail.vue';
import SearchResultDetailPlaceholder from './SearchResultDetailPlaceholder.vue';
import SearchSection from './SearchSection.vue';

export default {
  components: {
    SearchResult,
    SearchResultPlaceholder,
    SearchResultDetail,
    SearchResultDetailPlaceholder,
    SearchPagination,
    SearchSection,
  },
  data() {
    return {
      currentResult: { ...nullResult },
      loading: true,
      results: [],
      meta: {},
      hasPageChanged: false,
      query: { page: 1, job_id: 10 },
    };
  },
  computed: {
    showNonSaved() {
      return !this.loading && this.results.length === 0;
    },
    showList() {
      return !this.showNonSaved;
    },
  },
  watch: {
    results() {
      /**
       * Waiting for results to have changed fixes the bug of
       * scroll not scrolling. Checking for a page changed flag,
       * stops the bug of scrolling out side of pagination aka
       * init search.
       */
      if (this.hasPageChanged === true) {
        this.hasPageChanged = false;
        this.$el
          .querySelector('.result-list')
          .scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
      }
    },
  },
  mounted() {
    this.fetchDetails();
    this.fetchResults(this.resetCurrentResult);
  },
  methods: {
    setPage(page) {
      this.query.page = page;
      this.fetchResults();
    },
    fetchDetails() {
      client
        .get('/details')
        .then((response) => {
          detailStore.data = response.data.data;
        });
    },
    fetchResults(callback) {
      this.loading = true;

      client
        .get('/candidates/me/applications', { params: this.query })
        .then((response) => {
          this.results = response.data.data;
          this.meta = response.data.meta;
          if (callback) {
            callback(response);
          }
          this.loading = false;
          if (typeof resultsLoaded === 'object' && resultsLoaded.constructor.name === 'CustomEvent') {
            document.querySelector('body').dispatchEvent(resultsLoaded);
          }
        });
    },
    resetCurrentResult(response) {
      this.currentResult = response.data.data[0] || null;
    },
    openResult(result) {
      this.setCurrentResult(result);

      if (domHelper.notVisible(this.$refs.resultDetail.$el)) {
        window.location = result.links.share;
      }
    },
    setCurrentResult(result) {
      if (result === null) {
        this.currentResult = { ...nullResult };
      } else {
        this.currentResult = result;
      }
    },
  },
};
</script>

<style>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @font-face {
    font-family: 'Graphik';
    src: url('../fonts/Graphik-Regular.woff2') format('woff2'),
        url('../fonts/Graphik-Regular.woff') format('woff'),
        url('../fonts/Graphik-Regular.ttf') format('truetype'),
        url('../fonts/Graphik-Regular.svg#Graphik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
      font-family: 'Graphik';
      src: url('../fonts/Graphik-Medium.woff2') format('woff2'),
          url('../fonts/Graphik-Medium.woff') format('woff'),
          url('../fonts/Graphik-Medium.ttf') format('truetype'),
          url('../fonts/Graphik-Medium.svg#Graphik-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Athletics';
      src: url('../fonts/Athletics-Bold.woff2') format('woff2'),
          url('../fonts/Athletics-Bold.woff') format('woff'),
          url('../fonts/Athletics-Bold.ttf') format('truetype'),
          url('../fonts/Athletics-Bold.svg#Athletics-Bold') format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Athletics';
      src: url('../fonts/Athletics-Medium.woff2') format('woff2'),
          url('../fonts/Athletics-Medium.woff') format('woff'),
          url('../fonts/Athletics-Medium.ttf') format('truetype'),
          url('../fonts/Athletics-Medium.svg#Athletics-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Athletics';
      src: url('../fonts/Athletics-Regular.woff2') format('woff2'),
          url('../fonts/Athletics-Regular.woff') format('woff'),
          url('../fonts/Athletics-Regular.ttf') format('truetype'),
          url('../fonts/Athletics-Regular.svg#Athletics-Regular') format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'DM Sans';
      src: url('../fonts/DMSans-Bold.woff2') format('woff2'),
          url('../fonts/DMSans-Bold.woff') format('woff'),
          url('../fonts/DMSans-Bold.ttf') format('truetype'),
          url('../fonts/DMSans-Bold.svg#DMSans-Bold') format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'DM Sans';
      src: url('../fonts/DMSans-Medium.woff2') format('woff2'),
          url('../fonts/DMSans-Medium.woff') format('woff'),
          url('../fonts/DMSans-Medium.ttf') format('truetype'),
          url('../fonts/DMSans-Medium.svg#DMSans-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'DM Sans';
      src: url('../fonts/DMSans-Regular.woff2') format('woff2'),
          url('../fonts/DMSans-Regular.woff') format('woff'),
          url('../fonts/DMSans-Regular.ttf') format('truetype'),
          url('../fonts/DMSans-Regular.svg#DMSans-Regular') format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @layer components {
    .heading {
      font-family: 'DM Sans', sans-serif;
      font-size: 1.313rem;
    }
  }

</style>
