const nullResult = {
  id: null,
  title: '',
  company: {},
};

const nullQuery = {
  q: '',
  page: '1',
  location: '',
  lat: null,
  lng: null,
  radius: 50000,
  remote_status: [],
  experience_ids: [],
  min_salary: null,
  min_salary_freq: null,
  industry_ids: [],
  job_type_ids: [],
  size: null,
  company_size_id: [],
  benefit_ids: [],
  target_country_ids: [],
  language_types: [],
  role: [],
  per_page: 15,
  sort: 'publish_at',
  sort_dir: 'desc',
};

const nullFilters = {
  q: '',
  location: '',
  lat: null,
  lng: null,
  radius: 50000,
  remote_status: [],
  experience_ids: [],
  min_salary: 0,
  min_salary_freq: null,
  industry_ids: [],
  job_type_ids: [],
  company_size_id: [],
  benefit_ids: [],
  target_country_ids: [],
  language_types: [],
  role: [],
};

export { nullQuery, nullResult, nullFilters };
