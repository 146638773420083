<template>
  <search-filter
    :active="modelValue.length > 0"
    @open="unsavedStatus = modelValue"
    @reset="clear()"
    @save="$emit('update:modelValue', unsavedStatus)"
  >
    <span v-html="label" />
    <template #options>
      <div class="gs-space-y-3">
        <div
          v-for="option in options"
          :key="option.id"
          class="gs-flex gs-items-center gs-px-5 gs-py-4 gs-border gs-rounded-sm gs-border-beige-500 gs-flex gs-space-x-5"
          :class="{
            'gs-border-stone-500': isChecked(option.id)
          }"
        >
          <input
            :id="'remote-status-' + option.id"
            v-model="unsavedStatus"
            class="gs-appearance-none gs-shrink-0 gs-rounded-full gs-h-3 gs-w-3 gs-ring-1 gs-ring-offset-2 gs-ring-gray-800 gs-bg-white hover:gs-ring-2 checked:gs-bg-gray-800 gs-cursor-pointer"
            type="checkbox"
            :value="option.id"
            :checked="isChecked(option.id)"
          >
          <div style="max-width:270px">
            <label
              :for="'remote-status-' + option.id"
              class="gs-block gs-text-gray-800 gs-cursor-pointer"
            >
              {{ option.facetName }}
            </label>
            <label
              :for="'remote-status-' + option.id"
              class="gs-block gs-text-sm gs-text-gray-500 gs-cursor-pointer"
            >
              {{ option.description }}
            </label>
          </div>
        </div>
      </div>
    </template>
  </search-filter>
</template>

<script>
import SearchFilter from './SearchFilter.vue';
import { store as detailStore } from '../stores/detail';

export default {
  components: {
    SearchFilter,
  },
  props: {
    modelValue: { type: Array, default: () => [] },
    facet: { type: Object, default: () => {} },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      unsavedStatus: [],
    };
  },
  computed: {
    label() {
      if (this.options.length === 0) {
        return 'Loading...';
      }

      switch (this.modelValue.length) {
        case 0:
          return 'Workplace';

        case 1:
          return this.getOptionById(this.modelValue[0]).facetName;

        default:
          return `Workplace &middot; ${this.modelValue.length}`;
      }
    },
      options() {
          return [
              {
                  id: 'remote',
                  facetName: `Remote jobs (${this.facet?.remote ?? 0})`,
                  description: 'Only show fully remote jobs.',
                  value: 'remote',
              },
              {
                  id: 'office',
                  facetName: `Office / on-site jobs (${this.facet?.office ?? 0})`,
                  description: 'Only show fully on-site jobs.',
                  value: 'office',
              },
              {
                  id: 'hybrid',
                  facetName: `Hybrid jobs (${this.facet?.hybrid ?? 0})`,
                  description: 'Only show jobs with blend of in-office & remote.',
                  value: 'hybrid',
              },
          ];
      },
  },
  methods: {
    isChecked(value) {
      return this.unsavedStatus.includes(value);
    },
    clear() {
      this.unsavedStatus = [];
      this.$emit('update:modelValue', []);
    },
    getOptionById(id) {
      return this.options.find((option) => option.id === id);
    },
  },
};
</script>
