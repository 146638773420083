<template>
  <div>
    <div class="lg:gs-hidden gs-h-screen">
      <div class="gs-space-y-2 gs-text-lg gs-p-10">
        <p>
          Maps are not available at this screen size.
        </p>
        <p>Suggestions:</p>
        <div class="gs-prose">
          <ul>
            <li>Rotate your screen</li>
            <li><a @click="$emit('showList')">Change to list view</a></li>
            <li>Change to a desktop computer</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="gs-hidden lg:gs-flex gs-h-[calc(100vh_-_10.5rem)]" v-show="lat && lng">
      <div
        ref="resultsContainer"
        class="gs-basis-2/5 gs-overflow-y-auto gs-results-container"
      >
          <div v-if="meta.total>0">
              <div class="gs-p-10 gs-text-center gs-text-stone-600 gs-text-bold gs-text-lg">
                  <div v-if="!loading">{{ meta.total }} jobs at {{ facets.company_count }} companies</div>
                  <div v-else>Searching for jobs...</div>
              </div>

              <search-section
                  class="gs-bg-beige-100 gs-border-t gs-border-beige-500"
                  :loading="loading"
              >
                  <search-result
                      v-for="result in results.slice(0,5)"
                      :key="result.id"
                      :is-active="result.id == currentResult?.id"
                      :result="result"
                      :class="'gs-result-' + result.id"
                      @click="$emit('changeResult', result)"
                      @toggle-saved="result.meta.is_saved = !result.meta.is_saved"
                  />

                  <mid-page-unit
                      position="primary"
                  />

                  <search-result
                      v-for="result in results.slice(5,10)"
                      :key="result.id"
                      :is-active="result.id == currentResult?.id"
                      :result="result"
                      :class="'gs-result-' + result.id"
                      @click="$emit('changeResult', result)"
                      @toggle-saved="result.meta.is_saved = !result.meta.is_saved"
                  />

                  <mid-page-unit
                      v-if="(results.length>5)"
                      position="secondary"
                  />

                  <search-result
                      v-for="result in results.slice(10)"
                      :key="result.id"
                      :is-active="result.id == currentResult?.id"
                      :result="result"
                      :class="'gs-result-' + result.id"
                      @click="$emit('changeResult', result)"
                      @toggle-saved="result.meta.is_saved = !result.meta.is_saved"
                  />

                  <template #placeholder>
                      <search-result-placeholder
                          v-for="n in 15"
                          :key="n"
                      />
                  </template>
              </search-section>

              <!--<search-footer
                ref="footer"
                :query="query"
                @new-query="newQuery($event)"
              />-->
          </div>
          <div v-else>
              <div class="gs-p-10 gs-text-center gs-text-stone-600 gs-text-bold gs-text-lg">
                  We found no results for the current map area.
              </div>
          </div>
      </div>

      <div class="gs-basis-3/5" style="position: relative;">
          <div class="gagago-map-loading-overlay" v-if="loading">
              <div class="gagago-map-loading-ring"><div></div><div></div><div></div><div></div></div>
          </div>
        <GMapMap
          ref="gsMap"
          :zoom="11"
          :center="{lat: getLat(), lng: getLng()}"
          :options="mapOptions"
          class="gs-h-[calc(100vh_-_10.5rem)]"
        >
          <GMapMarker
            v-for="group in resultGroups"
            :key="group.id"
            :icon="group.icon"
            :position="group.location"
            :clickable="true"
            @click="$emit('changeResult', group.results[0])"
          >
            <GMapInfoWindow
              :opened="group.results.findIndex(result => result.id == currentResult?.id) !== -1"
              :options="{
                  disableAutoPan: true
              }"
            >
              <button
                class="gs-text-xl gs-absolute gs-top-0 gs-right-2"
                @click="$emit('changeResult', null)"
              >
                &times;
              </button>
              <div class="gs-p-2 gs-max-w-md">
                <div
                  v-for="result in group.results"
                  v-show="result.id === currentResult?.id"
                  :key="result.id"
                >
                  <div class="gs-flex gs-justify-between">
                    <img
                      class="gs-h-14 gs-w-14 gs-rounded-xl"
                      :src="(result.logo===null)? result.company.logo : result.logo"
                      :alt="result.company.name"
                    >
                    <search-result-actions
                      class="gs-ml-5 gs-justify-end gs-text-[15px]"
                      :open-tab="result.links.share"
                      :apply="result.links.apply"
                      :has-applied="result.meta.has_applied"
                      :application-status="result.meta.application_status"
                    />
                  </div>

                  <div class="gs-mt-5">
                    <h2 class="gs-text-2xl gs-text-twilight-900 gs-font-medium">
                      {{ result.title }}
                    </h2>
                    <div class="gs-flex gs-justify-start gs-space-x-5 gs-mt-2 gs-text-beige-400">
                      <span>
                        {{ result.location.name }}<template v-if="result.company.remote_status"> ({{ result.company.remote_status }})</template>
                      </span>
                      <span v-if="result.salary_display">
                        {{ result.salary_display }}
                      </span>
                      <span v-if="result.job_type && result.job_type.name">
                        {{ result.job_type.name }}
                      </span>
                      <span v-if="result.published_at_human">
                          <template v-if="result.published_at_human === 'Today' || result.published_at_human === 'Yesterday'">
                              Posted {{ result.published_at_human.toLowerCase() }}
                          </template>
                          <template v-else>
                              {{ result.published_at_human }}
                          </template>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="group.results.length > 1"
                >
                  <hr class="gs-border-t gs-border-beige-500 gs-my-2">
                  <h2 class="gs-font-medium">
                    More at this location...
                  </h2>
                  <div class="gs-flex gs-flex-wrap gs-gap-2 gs-mt-2">
                    <a
                      v-for="result in group.results"
                      v-show="result.id !== currentResult?.id"
                      :key="result.id"
                      class="gs-bg-beige-100 gs-border gs-border-beige-600 gs-rounded-full gs-text-beige-600 gs-text-xs gs-px-2 gs-py-1 gs-cursor-pointer"
                      @click="$emit('changeResult', result)"
                    >
                      {{ result.title }}
                    </a>
                  </div>
                </div>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
            <GMapCircle
                :radius="circleRadius"
                :center="{ lat: lat, lng: lng}"
            />
        </GMapMap>
      </div>
    </div>
      <div v-show="!lat || !lng">
          <div class="gs-p-10 gs-text-center gs-text-stone-600 gs-text-bold gs-text-lg">
            Please select a location from the search bar above to use map view
          </div>
      </div>
  </div>
</template>

<script>
import MidPageUnit from './MidPageUnit';
import SearchPagination from './SearchPagination.vue';
import SearchResult from './SearchResult.vue';
import SearchResultActions from './SearchResultActions.vue';
import SearchResultPlaceholder from './SearchResultPlaceholder.vue';
import SearchFooter from './SearchFooter.vue';
import SearchSection from './SearchSection.vue';
import { getAssetUrl } from '../utils/asset.js';

export default {
  components: {
    MidPageUnit,
    SearchFooter,
    SearchPagination,
    SearchResult,
    SearchResultActions,
    SearchResultPlaceholder,
    SearchSection,
  },
  props: {
    query: { type: Object, default: null },
    results: { type: Array, default: () => [] },
    loading: { type: Boolean, default: true },
    currentResult: { type: Object, default: null },
    meta: { type: Object, default: null },
    facets: { type: Object, default: null },
  },
  emits: ['newQuery', 'showList', 'changeResult', 'changePage', 'createAlert', 'new-query'],
  data() {
    return {
      map: null,
        mapDragFetch: false,
      singlePinIcon: new URL('../images/single-pin.png', import.meta.url).toString(),
      multiPinIcon: new URL('../images/multi-pin.png', import.meta.url).toString(),
      mapOptions: {
        maxZoom: 30,
        minZoom: 7,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapId: process.env.GOOGLE_MAP_ID,
        region: process.env.REGION,
      },
        lat: null,
        lng: null,
    };
  },
  computed: {
      circleRadius() {
          return this.query.radius;
      },
    resultGroups() {
      return this.results.reduce((groups, result) => {
        const groupId = `${result.location.lat}-${result.location.lng}`;
        let groupIndex = groups.findIndex((group) => group.id === groupId);

        if (groupIndex === -1) {
          groups.push({
            id: groupId,
            location: result.location,
            results: [],
          });

          groupIndex = groups.length - 1;
        }

        groups[groupIndex].results.push(result);

        return groups;
      }, [])
        .map((group) => {
          const icon = { icon: group.results.length > 1 ? this.multiPinIcon : this.singlePinIcon };
          return { ...group, ...icon };
        });
    },
  },
  watch: {
    currentResult() {
        if (this.currentResult !== null) {
            this.panToCurrentInfoWindow();
            this.scrollResultsToCurrent();
        }
    },
    results() {
      // this.zoomMapToFitMarkers();
      // this.setFirstResultAsCurrent();
    },
      loading() {
        if (this.loading) {
            this.disableMapControls();
        } else {
            this.enableMapControls();
        }
      },
    map() {
      this.addMapButton('Back to list view', () => this.$emit('showList'));
      this.addMapButton('Create an alert', () => this.$emit('createAlert'));
    },
      query: {
          handler(newValue, oldValue) {
              this.lat = newValue.lat;
              this.lng = newValue.lng;
              this.centerMap();
          },
          deep: true,
      },
  },
  mounted() {
      this.lat = this.query.lat;
      this.lng = this.query.lng;

    this.$refs.gsMap.$mapPromise.then((map) => {
      this.map = map;
      this.centerMap();

        this.map.addListener('dragend', () => {
            this.handleMapDrag();
        });
    });
  },
  methods: {
      panToCurrentInfoWindow() {
          this.map.setCenter({ lat: this.currentResult.location.lat, lng: this.currentResult.location.lng });
      },
      disableMapControls() {
        this.map.setOptions({
            gestureHandling: 'none',
            zoomControl: false,
        });
      },
      enableMapControls() {
          this.map.setOptions({
              gestureHandling: 'cooperative',
              zoomControl: true,
          });
      },
    asset($url) {
      return getAssetUrl($url);
    },
      handleMapDrag() {
        this.lat = this.map.getCenter().lat();
        this.lng = this.map.getCenter().lng();
        this.mapDragFetch = true;

        let currentQuery = this.$parent.query;
        currentQuery.lat = this.lat;
        currentQuery.lng = this.lng;
          currentQuery.per_page = 500;
          currentQuery.map_mode = 1;
        currentQuery.radius = this.query.radius;
          currentQuery.location = '';
        this.$emit('newQuery', currentQuery);
      },
    addMapButton(text, callback) {
      const controlUI = document.createElement('button');
      controlUI.innerText = text;
      controlUI.classList.add(
        'gs-text-[16px]',
        'gs-mt-4',
        'gs-ml-3',
        'gs-px-4',
        'gs-py-2',
        'gs-rounded-full',
        'gs-border',
        'gs-border-beige-500',
        'gs-bg-beige-100',
        'hover:gs-border-stone-500',
      );
      controlUI.addEventListener('click', callback);

      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlUI);
    },
    setFirstResultAsCurrent() {
      this.$emit('changeResult', this.results[0]);
    },
    scrollResultsToCurrent() {
        if (this.currentResult) {
            const resultCard = this.$el.querySelector(`.gs-result-${this.currentResult.id}`);

            if (resultCard) {
                resultCard.scrollIntoView({
                    block: 'nearest',
                    behavior: 'smooth',
                });
            }
        }
    },
      centerMap() {
          this.map.setCenter(new window.google.maps.LatLng(this.lat, this.lng));
      },
    zoomMapToFitMarkers() {
      const bounds = new window.google.maps.LatLngBounds();

      this.results.forEach((result) => {
        bounds.extend(result.location);
      });

      this.map.fitBounds(bounds);
    },
    refreshFooter() {
      // this.$refs.footer.refresh();
    },
    newQuery($event) {
      this.$refs.resultsContainer.scrollTo({ top: 0, behavior: 'smooth' });
      this.$emit('newQuery', $event);
    },
    getLat() {
        if (process.env.REGION === 'AU') {
            return -37.8142176;
        }

        return 53.5500;
    },
    getLng() {
        if (process.env.REGION === 'AU') {
          return 144.9631608;
        }

        return -2.4333;
    },
  },
};
</script>

<style>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer components {
    .gm-style-iw button[aria-label="Close"] {
      @apply gs-opacity-0 gs-pointer-events-none;
    }
  }

  .gagago-map-loading-overlay {
      position: absolute;
      inset: 0;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0,0,0,0.2);
  }

  .gagago-map-loading-ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
  }
  .gagago-map-loading-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #fff;
      border-radius: 50%;
      animation: gagago-map-loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
  }
  .gagago-map-loading-ring div:nth-child(1) {
      animation-delay: -0.45s;
  }
  .gagago-map-loading-ring div:nth-child(2) {
      animation-delay: -0.3s;
  }
  .gagago-map-loading-ring div:nth-child(3) {
      animation-delay: -0.15s;
  }
  @keyframes gagago-map-loading-ring {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }
</style>
