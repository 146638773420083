<template>
  <search-filter
    :active="modelValue.length > 0"
    @open="unsavedStatus = modelValue"
    @reset="clear()"
    @save="$emit('update:modelValue', unsavedStatus)"
  >
    <span v-html="label" />
    <template #options>
      <div
        v-for="option in options"
        :key="option.id"
        class="gs-flex gs-items-center gs-px-5 gs-py-2 gs-space-x-5"
        :class="{
          'gs-border-stone-800': isChecked(option.value)
        }"
      >
        <input
          :id="'industry-' + option.id"
          v-model="unsavedStatus"
          class="gs-appearance-none gs-shrink-0 gs-rounded-full gs-h-3 gs-w-3 gs-ring-1 gs-ring-offset-2 gs-ring-gray-800 gs-bg-white hover:gs-ring-2 checked:gs-bg-gray-800 gs-cursor-pointer"
          type="checkbox"
          :value="option.id"
          :checked="isChecked(option.id)"
        >
        <div style="max-width:270px">
          <label
            :for="'industry-' + option.id"
            class="gs-block gs-text-gray-800 gs-cursor-pointer"
          >
            {{ option.facetName }}
          </label>
        </div>
      </div>
    </template>
  </search-filter>
</template>

<script>
import SearchFilter from './SearchFilter.vue';
import { store as detailStore } from '../stores/detail';

export default {
  components: {
    SearchFilter,
  },
  props: {
    modelValue: { type: Array, default: () => [] },
    facet: { type: Object, default: () => {} },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      unsavedStatus: [],
    };
  },
  computed: {
    label() {
      if (this.options.length === 0) {
        return 'Loading...';
      }

      switch (this.modelValue.length) {
        case 0:
          return 'Company industry';

        case 1:
          return this.getOptionById(this.modelValue[0]).name;

        default:
          return `Company industry &middot; ${this.modelValue.length}`;
      }
    },
    options() {
      return detailStore
        .data
        .filter((detail) => detail.type === 'Industry')
        .sort((a, b) => ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0))// sort industries alphabetically by name
        .map((detail) => {
          const count = this.facet && this.facet.hasOwnProperty(detail.id) ? this.facet[detail.id] : 0;

          return {
            id: detail.id,
            name: detail.name,
            facetName: `${detail.name} (${count})`,
            description: detail.description,
          };
        });
    },
  },
  methods: {
    isChecked(value) {
      return this.unsavedStatus.includes(value);
    },
    clear() {
      this.unsavedStatus = [];
      this.$emit('update:modelValue', []);
    },
    getOptionById(id) {
      return this.options.find((option) => option.id === parseInt(id));
    },
  },
};
</script>
