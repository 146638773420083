import axios from "axios";

export default {
    install: (app, options) => {
        app.mixin({
            methods: {
                initGiveAnaly() {
                    //console.log('Registering analytics system');
                },
                registerGiveAnalyClickEvent(jobId) {
                    console.log('clicked: ' + jobId);
                    this.record(jobId, 'click');
                },
                registerGiveAnalyShareEvent(jobId) {
                    console.log('share');
                    this.record(jobId, 'share');
                },
                registerGiveAnalyApplyEvent(jobId) {
                    console.log('apply');
                    //this.record(jobId, 'apply-start'); disabled we now record server side
                },
                record(jobId, type) {
                    axios.post(process.env.PORTAL_DOMAIN + '/api/job/a', {
                        job_id: jobId,
                        type: type
                    }).then((response) => {

                    }).catch((error) => {
                        console.error(error);
                    });
                }
            },
        });
    },
};