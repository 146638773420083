<template>
  <search-filter
    :active="salary > 0"
    @open="setUnsaved()"
    @reset="clear()"
    @save="$emit('update:salary', Number(unsavedSalary)); $emit('update:freq', unsavedFreq);"
  >
    <span>{{ label }}</span>
    <template #options>
      <span class="gs-text-4xl">{{ displayUnsavedSalary }}</span>
      <input
        v-model="unsavedSalary"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        class="gs-mt-10 gs-w-full gs-h-1 gs-bg-gray-200 gs-rounded-lg gs-appearance-none gs-cursor-pointer"
      >
      <div class="gs-mt-5 gs-flex gs-justify-start gs-space-x-4">
        <button
          v-for="option in freqOptions"
          :key="option.id"
          class="hover:gs-text-gray-900"
          :class="{
            'gs-text-gray-500': option.value != unsavedFreq,
            'gs-text-gray-900': option.value == unsavedFreq
          }"
          @click="setUnsavedFreq(option.value)"
        >
          {{ option.name }}
        </button>
      </div>
    </template>
  </search-filter>
</template>

<script>
import SearchFilter from './SearchFilter.vue';
import * as region from '../utils/region';

export default {
  components: {
    SearchFilter,
  },
  props: {
    salary: { type: Number, default: 0 },
    freq: { type: String, default: 'Per year' },
  },
  emits: ['update:freq', 'update:salary'],
  data() {
    return {
      unsavedSalary: 0,
      unsavedFreq: 'Per year',
      currency: region.currencySymbol(),
      freqOptions: [
        {
          id: 1,
          name: 'Annual',
          value: 'Per year',
        },
        {
          id: 1,
          name: 'Monthly',
          value: 'Per month',
        },
        {
          id: 2,
          name: 'Daily',
          value: 'Per day',
        },
        {
          id: 3,
          name: 'Hourly',
          value: 'Per hour',
        },
      ],
    };
  },
  computed: {
    min() {
      return 0;
    },
    max() {
      switch (this.unsavedFreq) {
        case 'Per hour':
          return region.salaryFilterMaxHourly();

        case 'Per day':
          return region.salaryFilterMaxDaily();
        case 'Per month':
          return region.salaryFilterMaxMonthly();
        default:
          return region.salaryFilterMaxAnnual();
      }
    },
    step() {
      switch (this.unsavedFreq) {
        case 'Per hour':
          return 1;
        case 'Per day':
          return 10;
        case 'Per month':
          return 100;
        default:
          return 1000;
      }
    },
    label() {
      if (this.salary === null || this.salary === 0) {
        return 'Min salary';
      }

      if (this.salary < 1000) {
        return `${this.currency + this.salary}+`;
      }

      return `${this.currency + Math.round(this.salary / 1000)}K+`;
    },
    displayUnsavedSalary() {
      const salaryNum = Number(this.unsavedSalary);
      return `${this.currency + salaryNum.toLocaleString()}+`;
    },
  },
  methods: {
    setUnsavedFreq(value) {
      this.unsavedSalary = 0;
      if (value) {
        this.unsavedFreq = value;
      }
    },
    setUnsavedSalary(value) {
      if (value) {
        this.unsavedSalary = value;
      }
    },
    setUnsaved() {
      this.setUnsavedFreq(this.freq);
      this.setUnsavedSalary(this.salary);
    },
    clear() {
      this.unsavedSalary = 0;
      this.unsavedFreq = 'Per year';
      this.$emit('update:salary', 0);
      this.$emit('update:freq', null);
    },
  },
};
</script>

<style>
  /* All the same stuff for Firefox */
  input[type=range]::-moz-range-thumb {
    border-radius: 9999px;
    background: rgb(17 24 39);
  }

  /* Special styling for WebKit/Blink */
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    border-radius: 9999px;
    background: rgb(17 24 39);
    height: 1rem;
    width: 1rem;
  }

  /* All the same stuff for IE */
  input[type=range]::-ms-thumb {
    border-radius: 9999px;
    background: rgb(17 24 39);
  }
</style>
