import { createApp } from 'vue';
import Popper from "vue3-popper";
import VueGoogleMaps from '@fawmi/vue-google-maps';
import FontAwesomeIcon from './utils/fontawesome';
import SearchApp from './components/SearchApp.vue';
import SavedJobsApp from './components/SavedJobsApp.vue';
import SavedJobsDash from './components/SavedJobsDash.vue';
import RecommendedJobsApp from './components/RecommendedJobsApp.vue';
import RecommendedJobsDash from './components/RecommendedJobsDash.vue';
import ApplicationsApp from './components/ApplicationsApp.vue';
import ApplicationsDash from './components/ApplicationsDash.vue';
import ApplyFormHeader from './components/ApplyFormHeader.vue';
import FloatingVue from 'floating-vue';
import GiveAnalytics from './plugins/GiveAnalytics';
import 'floating-vue/dist/style.css';
import 'animate.css';

// Set build variables depending on env
globalThis.__VUE_OPTIONS_API__ = true;
globalThis.__VUE_PROD_DEVTOOLS__ = process.env.NODE_ENV === 'development';

if (document.getElementById('gagago-search')) {
  const searchApp = createApp(SearchApp);

  searchApp.use(VueGoogleMaps, {
    load: {
      key: process.env.GOOGLE_MAPS_KEY,
      libraries: 'places',
      region: process.env.REGION,
      types: ['(cities)'],
    },
  });

  searchApp.use(FloatingVue);
  searchApp.use(GiveAnalytics);

  searchApp.component('FontAwesomeIcon', FontAwesomeIcon);
  searchApp.component('Popper', Popper);
  searchApp.mount('#gagago-search');
}

if (document.getElementById('gagago-saved-jobs')) {
  const savedJobsApp = createApp(SavedJobsApp);

  savedJobsApp.use(FloatingVue);
  savedJobsApp.use(GiveAnalytics);

  savedJobsApp.component('FontAwesomeIcon', FontAwesomeIcon);
  savedJobsApp.component('Popper', Popper);
  savedJobsApp.mount('#gagago-saved-jobs');
}

if (document.getElementById('gagago-recommended-app')) {
  const recommendedJobsApp = createApp(RecommendedJobsApp);

  recommendedJobsApp.use(FloatingVue);
  recommendedJobsApp.use(GiveAnalytics);

  recommendedJobsApp.component('FontAwesomeIcon', FontAwesomeIcon);
  recommendedJobsApp.component('Popper', Popper);
  recommendedJobsApp.mount('#gagago-recommended-app');
}

if (document.getElementById('gagago-applications-app')) {
  const applicationsApp = createApp(ApplicationsApp);

  applicationsApp.use(FloatingVue);
  applicationsApp.use(GiveAnalytics);

  applicationsApp.component('FontAwesomeIcon', FontAwesomeIcon);
  applicationsApp.component('Popper', Popper);
  applicationsApp.mount('#gagago-applications-app');
}

if (document.getElementById('gagago-saved-dash')) {
  const savedJobsDash = createApp(SavedJobsDash);

  savedJobsDash.use(FloatingVue);
  savedJobsDash.use(GiveAnalytics);

  savedJobsDash.component('FontAwesomeIcon', FontAwesomeIcon);
  savedJobsDash.component('Popper', Popper);
  savedJobsDash.mount('#gagago-saved-dash');
}

if (document.getElementById('gagago-recommended-dash')) {
  const recommendedJobsDash = createApp(RecommendedJobsDash);

  recommendedJobsDash.use(FloatingVue);
  recommendedJobsDash.use(GiveAnalytics);

  recommendedJobsDash.component('FontAwesomeIcon', FontAwesomeIcon);
  recommendedJobsDash.component('Popper', Popper);
  recommendedJobsDash.mount('#gagago-recommended-dash');
}

if (document.getElementById('gagago-applications-dash')) {
  const applicationsDash = createApp(ApplicationsDash);

  applicationsDash.use(FloatingVue);
  applicationsDash.use(GiveAnalytics);

  applicationsDash.component('FontAwesomeIcon', FontAwesomeIcon);
  applicationsDash.component('Popper', Popper);
  applicationsDash.mount('#gagago-applications-dash');
}

if (document.getElementById('gagago-apply-form-header')) {
  const ApplyFormHeaderApp = createApp(ApplyFormHeader);

  ApplyFormHeaderApp.use(FloatingVue);
  ApplyFormHeaderApp.use(GiveAnalytics);

  ApplyFormHeaderApp.component('FontAwesomeIcon', FontAwesomeIcon);
  ApplyFormHeaderApp.component('Popper', Popper);
  ApplyFormHeaderApp.mount('#gagago-apply-form-header');
}
