<template>
  <div>
    <!-- LINK TO COMPAN"Y PAGE IF IT EXISTS -->
    <div
      v-if="!result.company.is_recruiter && result.company.links.page_url"
      class="gs-flex gs-justify-between  gs-p-2"
    >
      <a
        target="_blank"
        :href="result.company.links.page_url"
        title="Company Page"
      >
        <div class="gs-flex-1 gs-flex gs-flex-col lg:gs-flex-row gs-gap-x-1 gs-gap-y-4">

          <div
            v-if="result.company"
            class="gs-flex-none gs-mr-4 gs-w-14"
          >
            <img
              class="gs-h-14 gs-w-full gs-rounded-xl"
              :src="(result.logo===null)? result.company.logo : result.logo"
              :alt="result.company.name"
            >
          </div>
          <div class="gs-flex-none gs-mr-4 gs-my-4">
            <h2 class="gs-text-2xl lg:gs-text-base gs-font-medium gs-text-twilight-900 result-title">
              {{ result.company.name }}
            </h2>
          </div>
        </div>
      </a>
    </div>
    <!-- LOGO WITH NO LINK BECAUSE COMPANY PAGE DOES NOT EXIST -->
    <!-- <div v-else class="gs-flex gs-justify-between gs-bg-beige-200 gs-p-2">
        <div class="gs-flex-1 gs-flex gs-flex-col lg:gs-flex-row gs-gap-x-1 gs-gap-y-4">

          <div class="gs-flex-none gs-mr-4 gs-w-14" v-if="result.company">
            <img class="gs-h-14 gs-w-full gs-rounded-xl" :src="result.company.logo" :alt="result.company.name">
          </div>
          <div class="gs-flex-none gs-mr-4 gs-my-4">
            <h2 class="gs-text-2xl lg:gs-text-base gs-font-medium gs-text-twilight-900 result-title">
              {{ result.company.name }}
            </h2>
          </div>
        </div>
    </div> -->
    <!-- END V_ELSE -->
    <div class="gs-mt-5 gs-grid gs-grid-cols-1 gs-divide-y gs-text-twilight-900">
      <div
        v-if="result.secondary_industries"
        class="gs-flex gs-py-2 gs-justify-between"
      >
        <div>Sub-Industry</div>
        <div class="gs-text-right">
          <span v-for="secondary_industry in result.secondary_industries">{{ secondary_industry.name }}
            <br>
          </span>
        </div>
      </div>
      <div
        v-if="result.remote_status"
        class="gs-flex gs-py-2 gs-justify-between"
      >
        <span>Workplace</span>
        <span>{{ result.remote_status }}</span>
      </div>
      <div
        v-if="result.company_size"
        class="gs-flex gs-py-2 gs-justify-between"
      >
        <span>Company size</span>
        <span>{{ result.company_size.name }}</span>
      </div>
      <div
        v-if="result.additional_job_types"
        class="gs-flex gs-py-2 gs-justify-between"
      >
        <span>Additional job type</span>
        <div class="gs-text-right">
          <span v-for="additional_job_type in result.additional_job_types">{{ additional_job_type.name }}<br>
          </span>
        </div>
      </div>
      <div
        v-if="result.experience_level"
        class="gs-flex gs-py-2 gs-justify-between"
      >
        <span>Experience level</span>
        <span>{{ result.experience_level.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import SocialLink from './SocialLink.vue';
import { getAssetUrl } from '../utils/asset.js';

export default {
  components: {
    SocialLink,
  },
  props: {
    result: { type: Object, default: null },
  },
  methods: {
    asset($url) {
      return getAssetUrl($url);
    },
  },
};
</script>
