<template>
  <section>
    <div v-if="!loading">
      <slot />
    </div>
    <div
      v-if="loading"
      class="gs-animate-pulse"
    >
      <slot name="placeholder" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>
