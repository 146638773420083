import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faPlus, faMap, faChevronDown, faArrowDown, faArrowUp, faArrowRightLong, faArrowLeftLong, faHouse, faMagnifyingGlass, faLink, faUpRightFromSquare, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {
  faTwitter, faFacebook,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faUpRightFromSquare,
  faMap,
  faArrowDown,
  faArrowUp,
  faArrowRightLong,
  faArrowLeftLong,
  faChevronDown,
  faHouse,
  faMagnifyingGlass,
  faHeart,
  faTwitter,
  faFacebook,
  faEnvelope,
  faLink,
  faPlus,
  faXmark,
);

export default FontAwesomeIcon;
