<template>
  <button
    class="gs-px-6 gs-py-2 gs-rounded-full primary-btn hover:gs-bg-gradient-to-r hover:gs-from-orange-400 hover:gs-to-pink-400 hover:gs-shadow-md hover:gs-shadow-orange-400/"
  >
    <slot />
  </button>
</template>

<style scoped>

  .primary-btn {
    background-color: #0A2430;
    color: #F9F7F4;
  }

</style>
