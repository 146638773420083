<template>
  <div class="gs-min-h-screen gs-bg-beige-200 gs-text-granite-500">
    <header>
      <search-bar
        ref="searchBar"
        :query="query"
        :facets="facets"
        :show-filters-modal="showFiltersModal"
        @new-search="setQuery($event)"
        @toggle-filter-modal="showFiltersModal = !showFiltersModal"
      />
    </header>

    <main>
<!--      <div
        v-show="showNoResults && showList"
        class="gs-space-y-2 gs-text-lg gs-p-10 gs-flex gs-flex-col gs-items-center gs-mt-6"
      >
        <div class="gs-font-bold">
          <h3
            v-show="lastQuery.q"
            class="gs-text-2xl"
          >
            The search for <span class="gs-font-bold">"{{ lastQuery.q }}"</span> did
            not match any jobs.
          </h3>
          <h3
            v-show="!lastQuery.q"
            class="gs-text-2xl"
          >
            The tags applied did not match any jobs.
          </h3>
        </div>

        <div class="no-jobs-container gs-flex gs-items-end gs-justify-between gs-flex-col md:gs-flex-row -gs-mx-4">
          <div class="gs-w-full md:gs-w-1/2 gs-px-4 gs-text-center gs-pt-8">
            <span
              class="gs-block gs-pb-4"
              style="font-size: 42px;"
            >🔔</span>
            <p
              class="gs-text-lg gs-font-bold"
              style="margin-bottom:13px;"
            >
              Register to be notified when new jobs match your preferences.
            </p>
            <a
              :href="region === 'AU' ? 'https://secureau.giveagradago.com/candidate/register' : 'https://secure.giveagradago.com/candidate/register'"
              class="gs-btn gs-btn-primary gs-btn-secondary gs-mt-4"
            >
              Register now
            </a>
          </div>
          <div class="gs-w-full md:gs-w-1/2 gs-px-4 gs-pt-4 sm:gs-pt-0">
            <p class="gs-font-bold gs-pt-4">
              Search suggestions
            </p>

            <div class="gs-prose lg:gs-max-w-[260px]">
              <ul class="search-suggestions">
                  <li v-show="!lastQuery.q">Broaden your search by removing some filters.</li>
                <li
                  v-show="lastQuery.q"
                >
                  Try more general keywords
                </li>
                <li
                  v-show="lastQuery.q"
                >
                  Check your spelling
                </li>
                <li
                  v-show="lastQuery.q"
                >
                  Replace abbreviations with the entire word
                </li>
              </ul>
              <button
                type="button"
                class="gs-btn gs-btn-primary gs-mt-4"
                @click="$refs.searchBar.clearFilters()"
              >
                Reset applied filters
              </button>
            </div>
          </div>
        </div>
      </div>-->

      <div
        v-show="showNoLocation"
        class="gs-space-y-2 gs-text-lg gs-p-10"
      >
        <p>
          The location <span class="gs-font-bold">"{{ lastQuery.location }}"</span>
          could not be found.
        </p>
        <p class="gs-font-bold gs-pt-4">Search suggestions</p>
        <div class="gs-prose">
          <ul>
            <li>Select a option from the place suggestions</li>
            <li>Click the house icon to use your current location</li>
            <li>Use a postcode instead of a city and county</li>
            <li>Make sure the city and county are spelled correctly</li>
            <li>Replace abbreviations with the entire word</li>
          </ul>
        </div>
      </div>

      <search-map
        v-if="showMap"
        ref="searchMap"
        :query="query"
        :meta="meta"
        :loading="loading"
        :results="results"
        :current-result="currentResult"
        :facets="facets"
        @change-result="setCurrentResult($event)"
        @change-page="setPage($event)"
        @show-list="changeToListView()"
        @create-alert="toggleCreateAlert()"
        @new-query="setQuery($event)"
        @reset-query="$refs.searchBar.clearFilters()"
      />

      <search-list
        v-if="showList"
        ref="searchList"
        :query="query"
        :meta="meta"
        :loading="loading"
        :results="results"
        :current-result="currentResult"
        :facets="facets"
        :is-sort-desc="query.sort_dir=='desc'"
        @change-result="setCurrentResult($event)"
        @change-page="setPage($event)"
        @increment-page="setPageIncrement($event)"
        @show-map="changeToMapView()"
        @sort-dir="changeSortDir()"
        @create-alert="toggleCreateAlert()"
        @new-query="setQuery($event)"
        @reset-query="$refs.searchBar.clearFilters()"
      />
    </main>
  </div>

  <save-search-modal
    v-show="showCreateAlertModal"
    @save-search="saveSearch"
    @cancel="toggleCreateAlert()"
  />

  <save-search-response
    v-show="showSaveSearchResponse"
    @toggle-visibility="toggleSaveSearchResponse()"
  />

  <login-to-save-search-response
    v-show="showLoginToSaveSearchResponse"
    @toggle-login-visibility="toggleLoginSaveSearchResponse()"
    @login-to-save-search="loginToSaveSearch()"
  />
</template>

<script>
import client from '../utils/http';
import { store as detailStore } from '../stores/detail';
import { store as midPageUnitStore } from '../stores/mid-page-unit';
import * as urlHelper from '../utils/url';
import { nullResult, nullQuery, nullFilters } from '../utils/null-objects';
import SearchBar from './SearchBar.vue';
import SearchList from './SearchList.vue';
import SearchMap from './SearchMap.vue';
import SaveSearchModal from './SaveSearchModal.vue';
import SaveSearchResponse from './SaveSearchResponse.vue';
import LoginToSaveSearchResponse from './LoginToSaveSearchResponse.vue';
import {getAllStrings} from "../utils/url";
import { store as pagingStore } from '../stores/paging';

const mapView = 'map';
const listView = 'list';

export default {
  components: {
    SearchBar,
    SearchList,
    SearchMap,
    SaveSearchModal,
    SaveSearchResponse,
    LoginToSaveSearchResponse,
  },
  data() {
    return {
      showCreateAlertModal: false,
      showSaveSearchResponse: false,
      showLoginToSaveSearchResponse: false,
      saveSearchRedirect: false,
      showFiltersModal: false,
      loading: true,
      currentResult: { ...nullResult },
      currentView: listView,
      query: { ...nullQuery },
      queryHistory: [],
      results: [],
      meta: {},
      facets: {},
      region: process.env.REGION,
    };
  },
  computed: {
    lastQuery() {
      const last = this.queryHistory.slice(-1).pop();

      return last || { ...nullQuery };
    },
    showNoResults() {
      return !this.loading && !this.showFiltersModal && this.results.length === 0 && !this.showNoLocation;
    },
    showNoLocation() {
      return !this.loading && !this.showFiltersModal && this.query.location.length > 0 && this.query.lat === null && this.query.lng === null;
    },
    showMap() {
      return this.currentView === mapView/* && !this.showNoLocation && !this.showFiltersModal*/;
    },
    showList() {
      return this.currentView === listView/* && !this.showNoLocation && !this.showFiltersModal*/;
    },
    sort() {
      return this.showMap && this.query.lat && this.query.lng ? 'distance' : 'publish_at';
    },
  },
  mounted() {
      this.initGiveAnaly();
    this.fetchMidPageUnits();
    this.fetchDetails();
    window.addEventListener('popstate', this.pushUrlToQuery);
    this.pushUrlToQuery();
    this.pushScriptToQuery();
    this.fetchResults(this.resetCurrentResult);
  },
  methods: {
    changeSortDir() {
      if (this.query.sort_dir == 'desc') {
        this.query.sort_dir = 'asc';
      } else {
        this.query.sort_dir = 'desc';
      }
      this.query.page = 1;
      this.fetchResults(this.resetCurrentResult);
    },
    changeToMapView() {
      this.currentView = mapView;
      this.query.page = 1;
        this.query.per_page = 500;
      this.fetchResults(this.resetCurrentResult);
    },
    changeToListView() {
      this.currentView = listView;
      this.query.page = 1;
        this.query.per_page = 15;
      this.fetchResults(this.resetCurrentResult);
    },
    setCurrentResult(result) {
      if (result === null) {
        this.currentResult = { ...nullResult };
      } else {
          this.registerGiveAnalyClickEvent(result.id);

        this.currentResult = result;
      }
    },
      setPageIncrement(page) {
          this.query.page = page;
          this.fetchResults(null, true);
          this.pushQueryToUrl();
    },
    setPage(page) {
      this.query.page = page;
      this.fetchResults();
      this.pushQueryToUrl();
    },
    pushHistory(query) {
      this.queryHistory.push({ ...query });
    },
    setQuery(query) {
      this.query = query;
      this.query.page = '1';
      this.fetchResults(this.resetCurrentResult);
      this.pushQueryToUrl();
      this.refreshFooters();
    },
    refreshFooters() {
      if (this.showMap) {
        this.$refs.searchMap.refreshFooter();
      } else if (this.showList && this.$refs.searchList) {
        this.$refs.searchList.refreshFooter();
      }
    },
    pushScriptToQuery() {
      const searchScript = document.getElementById('gagago-search-script');
      if (searchScript) {
        const queryString = searchScript.getAttribute('json-query');
        if (searchScript) {
          const queryString = searchScript.getAttribute('json-query');
          if (queryString) {
            let jsonQuery = null;
            try {
              jsonQuery = JSON.parse(queryString);
            } catch (e) {
              console.error(e);
            }
            if (jsonQuery) {
              if (jsonQuery.q) {
                if (jsonQuery.q == 'null' || jsonQuery.q === 'null') {
                  this.query.q = '';
                } else {
                  this.query.q = jsonQuery.q;
                }
              }
              if (jsonQuery.page) {
                this.query.page = jsonQuery.page;
              }
              if (jsonQuery.location) {
                this.query.location = jsonQuery.location;
              }
              if (jsonQuery.lat) {
                this.query.lat = jsonQuery.lat;
              }
              if (jsonQuery.lng) {
                this.query.lng = jsonQuery.lng;
              }
              if (jsonQuery.radius) {
                this.query.radius = Number(jsonQuery.radius);
              }
              if (jsonQuery.remote_status) {
                this.query.remote_status = jsonQuery.remote_status;
              }
              if (jsonQuery.experience_ids) {
                this.query.experience_ids = jsonQuery.experience_ids;
              }
              if (jsonQuery.min_salary) {
                this.query.min_salary = jsonQuery.min_salary;
              }
              if (jsonQuery.min_salary_freq) {
                this.query.min_salary_freq = jsonQuery.min_salary_freq;
              }
              if (jsonQuery.industry_ids) {
                this.query.industry_ids = jsonQuery.industry_ids;
              }
              if (jsonQuery.job_type_ids) {
                this.query.job_type_ids = jsonQuery.job_type_ids;
              }
                if (jsonQuery.role) {
                    this.query.role = jsonQuery.role;
                }
              if (jsonQuery.company_size_id) {
                this.query.company_size_id = jsonQuery.company_size_id;
              }
              if (jsonQuery.benefit_ids) {
                this.query.benefit_ids = jsonQuery.benefit_ids;
              }
                if (jsonQuery.target_country_ids) {
                    this.query.target_country_ids = jsonQuery.target_country_ids;
                }
                if (jsonQuery.language_types) {
                    this.query.language_types = jsonQuery.language_types;
                }
            }
          }
        }
      }
    },
    pushUrlToQuery() {
      const url = urlHelper.current();
      this.query.q = url.searchParams.get('q') || '';
      this.query.page = url.searchParams.get('page') || '1';
      this.query.location = url.searchParams.get('location') || '';
      this.query.lat = url.searchParams.get('lat') ? Number(url.searchParams.get('lat')) : null;
      this.query.lng = url.searchParams.get('lng') ? Number(url.searchParams.get('lng')) : null;
      this.query.radius = url.searchParams.get('radius') ? Number(url.searchParams.get('radius')) : 15000;
      this.query.remote_status = urlHelper.getAllStrings(url, 'remote_status') || [];
      this.query.experience_ids = urlHelper.getAllIds(url, 'experience_ids') || [];
      this.query.min_salary = Number(url.searchParams.get('min_salary'));
      this.query.min_salary_freq = url.searchParams.get('min_salary_freq');
      this.query.industry_ids = urlHelper.getAllIds(url, 'industry_ids') || [];
      this.query.job_type_ids = urlHelper.getAllIds(url, 'job_type_ids') || [];
        this.query.role = urlHelper.getAllIds(url, 'role') || [];
      this.query.company_size_id = urlHelper.getAllIds(url, 'company_size_id') || [];
      this.query.benefit_ids = urlHelper.getAllIds(url, 'benefit_ids') || [];
        this.query.target_country_ids = urlHelper.getAllIds(url, 'target_country_ids') || [];
        this.query.language_types = urlHelper.getAllStrings(url, 'language_types') || [];
    },
    pushQueryToUrl() {
      const url = urlHelper.current();
      url.searchParams.set('q', this.query.q);
      url.searchParams.set('page', this.query.page);

      url.searchParams.delete('location');
      url.searchParams.delete('lat');
      url.searchParams.delete('lng');
      url.searchParams.delete('radius');
      url.searchParams.delete('remote_status');
      url.searchParams.delete('experience_ids');
      url.searchParams.delete('min_salary');
      url.searchParams.delete('min_salary_freq');
      url.searchParams.delete('industry_ids');
      url.searchParams.delete('job_type_ids');
        url.searchParams.delete('role');
      url.searchParams.delete('company_size_id');
      url.searchParams.delete('benefit_ids');
        url.searchParams.delete('target_country_ids');
        url.searchParams.delete('language_types');

      if (this.query.location !== '') {
        url.searchParams.set('location', this.query.location);
      }

      if (this.query.lat !== null) {
        url.searchParams.set('lat', this.query.lat);
      }

      if (this.query.lng !== null) {
        url.searchParams.set('lng', this.query.lng);
      }

      if (this.query.radius !== 0) {
        url.searchParams.set('radius', this.query.radius);
      }

      if (this.query.remote_status.length) {
          this.query.remote_status.forEach((value) => url.searchParams.append('remote_status', value));
      }

      if (this.query.experience_ids.length) {
        this.query.experience_ids.forEach((value) => url.searchParams.append('experience_ids', value));
      }

      if (this.query.min_salary !== 0) {
        url.searchParams.set('min_salary', this.query.min_salary);
      }

      if (this.query.min_salary_freq !== null) {
        url.searchParams.set('min_salary_freq', this.query.min_salary_freq);
      }

      if (this.query.industry_ids.length) {
        this.query.industry_ids.forEach((value) => url.searchParams.append('industry_ids', value));
      }

      if (this.query.job_type_ids.length) {
        this.query.job_type_ids.forEach((value) => url.searchParams.append('job_type_ids', value));
      }

        if (this.query.role.length) {
            this.query.role.forEach((value) => url.searchParams.append('role', value));
        }

      if (this.query.company_size_id.length) {
        this.query.company_size_id.forEach((value) => url.searchParams.append('company_size_id', value));
      }

      if (this.query.benefit_ids.length) {
        this.query.benefit_ids.forEach((value) => url.searchParams.append('benefit_ids', value));
      }

        if (this.query.target_country_ids.length) {
            this.query.target_country_ids.forEach((value) => url.searchParams.append('target_country_ids', value));
        }

        if (this.query.language_types.length) {
            this.query.language_types.forEach((value) => url.searchParams.append('language_types', value));
        }

      urlHelper.pushUrl(url);
    },
    resetCurrentResult(response) {
        if (!this.showMap) {
            this.currentResult = response.data.data[0] || null;
        } else {
            this.currentResult = null;
        }
    },
    fetchDetails() {
      client
        .get('/details')
        .then((response) => {
          detailStore.data = response.data.data;
        });
    },
    fetchMidPageUnits() {
      client
        .get('/mid-page-units')
        .then((response) => {
          midPageUnitStore.data = response.data.data;
        });
    },
    fetchResults(callback, concatMode=false) {
      this.query.sort = this.sort;
      this.pushHistory(this.query);
        if(!concatMode) {
            this.results = [];
            this.loading = true;
        }

      client
        .get('/jobs', { params: this.query })
        .then((response) => {
            if(concatMode) {
                this.results = this.results.concat(response.data.data)
            } else {
                this.results = response.data.data;
            }
          this.meta = response.data.meta;
            pagingStore.no_more_results = (this.meta.last_page===this.meta.current_page);
          this.facets = response.data.facets;
          this.refreshFooters();
          if (callback) {
            callback(response);
          }
          this.loading = false;
        });
    },
    toggleCreateAlert() {
      this.showCreateAlertModal = !this.showCreateAlertModal;
    },
    async saveSearch(name, freq) {
      this.toggleCreateAlert();
      try {
        const payload = { name, frequency: freq, query: this.query };
        await client.post('/candidate/me/save-search', payload).then((response) => {
          console.log(response.data);
          if (typeof response.data.route !== 'undefined') {
            this.saveSearchRedirect = response.data.route;
            this.toggleLoginSaveSearchResponse();
          } else {
            this.toggleSaveSearchResponse();
          }
        });
      } catch (error) {
        alert('Error creating alert');
      }
    },
    toggleSaveSearchResponse() {
      this.showSaveSearchResponse = !this.showSaveSearchResponse;
    },
    toggleLoginSaveSearchResponse() {
      this.showLoginToSaveSearchResponse = !this.showLoginToSaveSearchResponse;
    },
    loginToSaveSearch() {
      window.location.assign(this.saveSearchRedirect);
    },
  },
};
</script>

<style>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  .search-suggestions li{
      line-height: 1.3;
      font-size: 14px;
  }

  .gs-btn-secondary{
    background-color: rgb(155, 239, 194) !important;
    background-image: linear-gradient(90deg, rgb(155, 239, 194) 0%, rgb(133, 242, 209) 50%, rgb(142, 255, 250) 100%) !important;
  }

  .no-jobs-container{
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .no-jobs-container{
      width: 660px;
    }
  }
</style>
<style scoped src="../../app.css"></style>
