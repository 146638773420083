<template>
  <div class="overlay">
    <div class="dialog">
      <div class="content">
        <div class="content-inner">
          <h2 class="title">
            Create a new job alert
            <div class="info">
              <img
                src="../images/information-circle.svg"
                alt="info"
                class="img-fluid"
              >
              <span class="info-message">You will receive an email when a job matches your saved search.</span>
            </div>
          </h2>

            <div style="margin-bottom: 20px;">
                <p style="font-weight: bold;">First, filter your preferences in the previous job search page.<br/>
                <i>For example you might search for: Marketing jobs in London.</i></p>
                <p>Name your alert, and select how often you'd like emails regarding your filtered job search. Edit your alerts at any time in your account.</p>
            </div>

          <p class="description">
            Alert name
          </p>
          <input
            id="alertName"
            v-model="name"
            :class="nameError == true ? 'gs-w-full gs-h-12 gs-border gs-border-beige-500 focus:gs-outline-none focus:gs-border-stone-500 input-error' : 'gs-w-full gs-h-12 gs-border gs-border-beige-500 focus:gs-outline-none focus:gs-border-stone-500'"
            type="text"
            placeholder="Eg. Junior Marketing Roles Oxford"
            @keyup="validate()"
          >

          <p
            v-if="nameError"
            class="text-red"
          >
            Please enter valid name
          </p>

          <br>
          <br>

          <p class="description">
            Alert frequency
          </p>
          <select
            id="alertFreq"
            v-model="frequency"
            :class="frequencyError == true ? 'gs-h-12 gs-w-full form-select gs-rounded-r-full gs-border-0 gs-bg-white input-error' : 'gs-h-12 gs-w-full form-select gs-rounded-r-full gs-border-0 gs-bg-white'"
          >
            <option value="daily">
              Daily
            </option>
            <option value="weekly">
              Weekly
            </option>
            <option value="monthly">
              Monthly
            </option>
          </select>
          <p
            v-if="frequencyError"
            class="text-red"
          >
            Please select frequency
          </p>
        </div>

        <div class="footer">
          <div class="footer-inner">
            <button
              class="cancel"
              @click="cancel()"
            >
              Cancel
            </button>

            <button
              class="confirm"
              @click="saveSearch()"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  props: {

  },
  emits: ['saveSearch', 'cancel'],
  data() {
    return {
      name: '',
      frequency: '',
      isValid: false,
      nameError: false,
      frequencyError: false,
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    validate() {
      const name = document.getElementById('alertName').value;
      this.isValid = name != null && name.length > 0;
    },
    cancel() {
      this.$emit('cancel');
      this.reset();
    },
    saveSearch() {
      // if(this.isValid){
      const name = document.getElementById('alertName').value;
      const freq = document.getElementById('alertFreq').value;

      if (!this.name) {
        this.nameError = true;
      } else {
        this.nameError = false;
      }

      if (!this.frequency) {
        this.frequencyError = true;
      } else {
        this.frequencyError = false;
      }

      if (this.name && this.frequency) {
        this.$emit('saveSearch', name, freq);
        this.reset();
      }
      // }
    },
    reset() {
      document.getElementById('alertName').value = '';
      document.getElementById('alertFreq').value = 'daily';
      this.validate();
    },
  },
};

</script>

<style scoped>
  .input-error {
    border: 1px solid red !important;
  }

  .text-red {
    color: red !important;
  }
</style>

<style scoped>
.overlay {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
}
.dialog {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.3rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  max-width: 100%;
  width: 40rem;
}

.content-inner {
  padding: 3rem 1.75rem;
}

.title {
  font-weight: 700;
  font-size: 1.25rem;
  font-family: 'Athletics', sans-serif;
  line-height: 1.75rem;
  margin-bottom: 2rem;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  display: flex;
  align-items: center;
  gap: 1rem;
}

#alertName {
  padding: 0.5rem 2rem 0.5rem 0.8rem;
  border-radius: 5px;
  border: 1px solid #DCD9D3;
  color: #474747;
  font-family: 'DM Sans', sans-serif;
  font-size: 0.875rem;
}

#alertName::placeholder {
  color: #A0A09E;
}

#alertFreq {
  border: 1px solid #DCD9D3;
  border-radius: 5px;
  color: #474747;
  font-family: 'DM Sans', sans-serif;
  font-size: 0.875rem;
  padding: 0.5rem 2rem 0.5rem 0.8rem;
  position: relative;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.description {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  font-family: 'DM Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}
.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #DCD9D3;
}

.footer-inner {
  padding: 0 1.75rem;
}

.cancel {
  border-radius: 0.75rem;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  margin-right: 1rem;
}
.cancel:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.cancel{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.cancel:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}
.cancel{
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.cancel:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.confirm {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #0A2430;
  color: #FFFFFF;
  font-family: 'DM Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 25px;
  padding: 0.7rem 2.9rem;
}

.confirm:hover {
  background-image: linear-gradient(to left, #8efffa 0%, #85f2d1 52.2%, #9befc2 100%);
  color: #0A2430;
}

.info {
  position: relative;
  display: inline-block;
}

.info .info-message {
  visibility: hidden;
  background-color: #0A2430;
  color: #fff;
  padding: 1rem;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: 200%;
  left: 50%;
  margin-left: -240px;
  width: 18rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.info .info-message::after {/* arrow on tooltip */
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 83%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

@media (min-width: 576px) {
  .info .info-message {
    width: 25rem;
    margin-left: -200px;
  }

  .info .info-message::after {
    left: 50%;
  }

}

.info:hover .info-message {
  visibility: visible;
}

</style>
