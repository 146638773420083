export function region() {
  return process.env.REGION;
}

export function currencySymbol() {
  if (process.env.REGION == 'GB') {
    return '£';
  }
  return '$';
}

export function salaryFilterMaxAnnual() {
  if (process.env.REGION == 'GB') {
    return 150000;
  }
  return 300000;
}

export function salaryFilterMaxMonthly() {
  if (process.env.REGION == 'GB') {
    return 10000;
  }
  return 20000;
}

export function salaryFilterMaxDaily() {
  if (process.env.REGION == 'GB') {
    return 1000;
  }
  return 2000;
}

export function salaryFilterMaxHourly() {
  if (process.env.REGION == 'GB') {
    return 100;
  }
  return 200;
}
