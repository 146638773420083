<template>
  <div>
    <div v-if="results.length>0 || loading">
        <div class="gs-flex gs-bg-beige-300 lg:gs-pb-10 lg:gs-gap-4 lg:gs-pt-10 lg:gs-px-4 search-list">
            <div class="gs-basis-full lg:gs-basis-1/2">
                <search-header
                    class="gs-hidden lg:gs-flex"
                    :total="meta.total"
                    :company-count="facets.company_count"
                    :is-sort-desc="isSortDesc"
                    @show-map="$emit('showMap')"
                    @sort-dir="$emit('sortDir')"
                    @create-alert="$emit('createAlert')"
                />

                <search-section class="result-list lg:gs-mt-5" :loading="loading">
                    <search-result
                        v-for="result in results.slice(0,5)"
                        :key="result.id"
                        :is-active="result.id == currentResult.id"
                        :result="result"
                        @click="openResult(result)"
                        @toggle-saved="result.meta.is_saved = !result.meta.is_saved"
                    />

                    <mid-page-unit position="primary"/>

                    <search-result
                        v-for="result in results.slice(5,10)"
                        :key="result.id"
                        :is-active="result.id == currentResult.id"
                        :result="result"
                        @click="openResult(result)"
                        @toggle-saved="result.meta.is_saved = !result.meta.is_saved"
                    />

                    <mid-page-unit v-if="(results.length>5)" position="secondary" />

                    <search-result
                        v-for="result in results.slice(10)"
                        :key="result.id"
                        :is-active="result.id == currentResult.id"
                        :result="result"
                        class="lg:last:gs-rounded-b-xl"
                        @click="openResult(result)"
                        @toggle-saved="result.meta.is_saved = !result.meta.is_saved"
                    />

                    <InfiniteLoading @infinite="loadMoreResults">
                        <template #spinner>
                            <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                                <div class="gagago-dual-ring-loader" v-if="!noMoreResults"></div>
                            </div>
                        </template>
                        <template #complete>
                            <div style="text-align: center;">
                                <span>No more results</span>
                            </div>
                        </template>
                    </InfiniteLoading>

                    <template #placeholder>
                        <search-result-placeholder
                            v-for="n in 15"
                            :key="n"
                        />
                    </template>
                </search-section>

<!--                <search-pagination
                    v-if="meta.links"
                    :links="meta.links"
                    :current-page="meta.current_page"
                    :last-page="meta.last_page"
                    :facets="facets"
                    class="gs-mt-10"
                    @change-page="hasPageChanged = true; $emit('changePage', $event);"
                />-->

                <search-footer
                    ref="footer"
                    :query="query"
                    @new-query="$emit('newQuery', $event)"
                />

            </div>

            <search-section
                ref="resultDetail"
                class="gs-hidden lg:gs-block gs-basis-1/2 gs-sticky gs-max-h-screen gs-top-0 gs-bg-beige-100 gs-rounded-xl result-detail"
                :loading="loading"
            >
                <search-result-detail
                    v-if="currentResult"
                    :result="currentResult"
                    @toggle-saved="currentResult.meta.is_saved = !currentResult.meta.is_saved"
                />

                <template #placeholder>
                    <search-result-detail-placeholder />
                </template>
            </search-section>
        </div>
    </div>
    <div v-else>
        <div class="gs-space-y-2 gs-text-lg gs-p-10 gs-flex gs-flex-col gs-items-center gs-mt-6">
            <div class="gs-font-bold">
                <h3 v-show="query.q" class="gs-text-2xl">
                    The search for <span class="gs-font-bold">"{{ query.q }}"</span> did not match any jobs.
                </h3>
                <h3 v-show="!query.q" class="gs-text-2xl">
                    The tags applied did not match any jobs.
                </h3>
            </div>

            <div class="no-jobs-container gs-flex gs-items-end gs-justify-between gs-flex-col md:gs-flex-row -gs-mx-4">
                <div class="gs-w-full md:gs-w-1/2 gs-px-4 gs-text-center gs-pt-8">
                    <span class="gs-block gs-pb-4" style="font-size: 42px;">🔔</span>
                    <p class="gs-text-lg gs-font-bold" style="margin-bottom:13px;">
                        Register to be notified when new jobs match your preferences.
                    </p>
                    <a :href="region === 'AU' ? 'https://secureau.giveagradago.com/candidate/register' : 'https://secure.giveagradago.com/candidate/register'" class="gs-btn gs-btn-primary gs-btn-secondary gs-mt-4">
                        Register now
                    </a>
                </div>
                <div class="gs-w-full md:gs-w-1/2 gs-px-4 gs-pt-4 sm:gs-pt-0">
                    <p class="gs-font-bold gs-pt-4">
                        Search suggestions
                    </p>

                    <div class="gs-prose lg:gs-max-w-[260px]">
                        <ul class="search-suggestions">
                            <li>Broaden your search by removing some filters.</li>
                            <li>Try more general keywords</li>
                            <li>Check your spelling</li>
                            <li>Replace abbreviations with the entire word</li>
                        </ul>
                        <button type="button" class="gs-btn gs-btn-primary gs-mt-4" @click="$emit('resetQuery')">
                            Reset applied filters
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import * as domHelper from '../utils/dom';
import MidPageUnit from './MidPageUnit';
import SearchPagination from './SearchPagination.vue';
import SearchResult from './SearchResult.vue';
import SearchResultPlaceholder from './SearchResultPlaceholder.vue';
import SearchResultDetail from './SearchResultDetail.vue';
import SearchResultDetailPlaceholder from './SearchResultDetailPlaceholder.vue';
import SearchHeader from './SearchHeader.vue';
import SearchFooter from './SearchFooter.vue';
import SearchSection from './SearchSection.vue';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import { store as pagingStore } from '../stores/paging';

export default {
  components: {
    MidPageUnit,
    SearchFooter,
    SearchHeader,
    SearchResult,
    SearchResultPlaceholder,
    SearchResultDetail,
    SearchResultDetailPlaceholder,
    SearchPagination,
    SearchSection,
      InfiniteLoading
  },
  props: {
    query: { type: Object, default: null },
    results: { type: Array, default: () => [] },
    loading: { type: Boolean, default: true },
    currentResult: { type: Object, default: null },
    meta: { type: Object, default: null },
    facets: { type: Object, default: null },
    isSortDesc: { type: Boolean, default: true },
  },
  emits: ['changePage', 'incrementPage', 'changeResult', 'showMap', 'sortDir', 'createAlert', 'newQuery', 'resetQuery'],
  data() {
    return {
        infiniteLoadingComplete: false,
      hasPageChanged: false,
        region: process.env.REGION,
    };
  },
  watch: {
    results() {
      /**
       * Waiting for results to have changed fixes the bug of
       * scroll not scrolling. Checking for a page changed flag,
       * stops the bug of scrolling out side of pagination aka
       * init search.
       */
      if (this.hasPageChanged === true) {
        this.hasPageChanged = false;
        this.$el
          .querySelector('.result-list')
          .scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
      }
    },
  },
  mounted() {

  },
    computed: {
        noMoreResults() {
            return pagingStore.no_more_results;
        }
    },
  methods: {
      loadMoreResults() {
          let nextPage = this.meta.current_page + 1;
          let lastPage = this.meta.last_page;
          if(nextPage<=lastPage) {
              this.$emit('incrementPage', nextPage);
          } else {
          }
      },
    openResult(result) {
      this.$emit('changeResult', result);

      if (domHelper.notVisible(this.$refs.resultDetail.$el)) {
        window.location = result.links.share;
      }
    },
    refreshFooter() {
      //this.$refs.footer.refresh();
    },
  },
};
</script>

<style scoped>
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

  .search-list {
    background: #F9F7F4;
  }
  .search-list .result-detail {
    overflow: hidden;
  }

  .search-list .result-detail {
      max-height: calc(100vh - 100px);
      top: 96px;
  }

    .search-suggestions li{
        line-height: 1.3;
        font-size: 14px;
    }

    .gs-btn-secondary{
        background-color: rgb(155, 239, 194) !important;
        background-image: linear-gradient(90deg, rgb(155, 239, 194) 0%, rgb(133, 242, 209) 50%, rgb(142, 255, 250) 100%) !important;
    }

    .no-jobs-container{
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        .no-jobs-container{
            width: 660px;
        }
    }

    .v3-infinite-loading {
        height: 100px;
    }

    .gagago-dual-ring-loader {
        /* change color here */
        color: #1c4c5b
    }
    .gagago-dual-ring-loader,
    .gagago-dual-ring-loader:after {
        box-sizing: border-box;
    }
    .gagago-dual-ring-loader {
        display: inline-block;
        width: 80px;
        height: 80px;
    }
    .gagago-dual-ring-loader:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6.4px solid currentColor;
        border-color: currentColor transparent currentColor transparent;
        animation: gagago-dual-ring-loader 1.2s linear infinite;
    }
    @keyframes gagago-dual-ring-loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

</style>
<style scoped src="../../app.css"></style>