<template>
  <div class="gs-m-6 gs-max-h-[calc(100vh_-_2rem)] gs-overflow-y-hidden">
    <div class="gs-flex gs-justify-between">
      <div class="gs-rounded-xl gs-bg-beige-500 gs-h-14 gs-w-14" />
      <div class="gs-flex gs-space-x-2">
        <div class="gs-rounded-full gs-bg-beige-500 gs-h-11 gs-w-20" />
      </div>
    </div>
    <div class="gs-mt-5">
      <div class="gs-flex-1 gs-space-y-6 gs-py-1">
        <div class="gs-h-2 gs-bg-beige-500 gs-rounded" />
        <div class="gs-grid gs-grid-cols-4 gs-gap-4 gs-mt-1">
          <div class="gs-h-2 gs-bg-beige-500 gs-rounded gs-col-span-2" />
          <div class="gs-h-2 gs-bg-beige-500 gs-rounded gs-col-span-1" />
          <div class="gs-h-2 gs-bg-beige-500 gs-rounded gs-col-span-1" />
        </div>
      </div>
      <div class="gs-flex gs-justify-start gs-space-x-2 gs-mt-4">
        <div class="gs-rounded-full gs-bg-beige-500 gs-h-5 gs-w-20" />
        <div class="gs-rounded-full gs-bg-beige-500 gs-h-5 gs-w-20" />
        <div class="gs-rounded-full gs-bg-beige-500 gs-h-5 gs-w-20" />
      </div>
    </div>
  </div>
</template>
