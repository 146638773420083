<template>
  <div class="gs-flex gs-space-x-2">
    <a
      v-if="openTab"
      :href="openTab"
      target="_blank"
      class="gs-mt-4 gs-px-1"
    >
      <font-awesome-icon
        :icon="['fas', 'up-right-from-square']"
        class="gs-mr-2"
      />
      Open
    </a>
    <div
      v-if="share"
      class="gs-relative gs-w-[71px] gs-h-[56px]"
    >
      <a
        class="gs-z-10 gs-absolute gs-top-3 gs-left-0 gs-inline-flex gs-items-center gs-px-1"
        :href="share.replace('www.', '').replace(/\/$/, '')+'/'"
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
          width="18"
          height="18"
          class="gs-mr-2"
        ><g
          fill="none"
          fill-rule="evenodd"
        ><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8" /></g></svg>

        Open
      </a>
    </div>
    <div
      v-if="share"
      class="gs-relative gs-w-[71px] gs-h-[56px]"
    >
      <Transition
        leave-active-class="animate__animated animate__fadeOutUp"
        @after-leave="showCopied = true"
      >
        <p
          v-if="showCopied"
          class="gs-z-0 gs-opacity-0 gs-absolute gs-top-2 gs-left-3"
        >
          Copied!
        </p>
      </Transition>

      <button
        class="gs-z-10 gs-absolute gs-top-3 gs-left-0 gs-inline-flex gs-items-center gs-px-1"
        @click.stop="copyLink"
      >
        <svg
          class="gs-mr-2"
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 9.72C9.49333 9.72 9.04 9.92 8.69333 10.2333L3.94 7.46666C3.97333 7.31333 4 7.16 4 7C4 6.83999 3.97333 6.68666 3.94 6.53333L8.64 3.79333C9 4.12666 9.47333 4.33333 10 4.33333C11.1067 4.33333 12 3.44 12 2.33333C12 1.22666 11.1067 0.333328 10 0.333328C8.89333 0.333328 8 1.22666 8 2.33333C8 2.49333 8.02667 2.64666 8.06 2.79999L3.36 5.54C3 5.20666 2.52667 5 2 5C0.893333 5 0 5.89333 0 7C0 8.10666 0.893333 9 2 9C2.52667 9 3 8.79333 3.36 8.46L8.10667 11.2333C8.07333 11.3733 8.05333 11.52 8.05333 11.6667C8.05333 12.74 8.92667 13.6133 10 13.6133C11.0733 13.6133 11.9467 12.74 11.9467 11.6667C11.9467 10.5933 11.0733 9.72 10 9.72Z"
            fill="#7A7A7A"
          />
        </svg>
        Share
      </button>
    </div>
    <button
      v-if="save"
      :disable="saveDisabled"
      class="save-button gs-relative gs-inline-flex gs-items-center gs-justify-center gs-w-11 gs-h-11 gs-text-xl gs-rounded-full gs-border gs-border-gray-300 gs-text-gray-500 hover:gs-border-gray-900 hover:gs-text-gray-900"
      :class="{
        'gs-border-gray-900': isSaved,
      }"
      @click.stop="toggleSaveResult"
    >
      <svg
        v-show="isSaved === false"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.375 0.25C10.07 0.25 8.8175 0.8575 8 1.8175C7.1825 0.8575 5.93 0.25 4.625 0.25C2.315 0.25 0.5 2.065 0.5 4.375C0.5 7.21 3.05 9.52 6.9125 13.03L8 14.0125L9.0875 13.0225C12.95 9.52 15.5 7.21 15.5 4.375C15.5 2.065 13.685 0.25 11.375 0.25ZM8.075 11.9125L8 11.9875L7.925 11.9125C4.355 8.68 2 6.5425 2 4.375C2 2.875 3.125 1.75 4.625 1.75C5.78 1.75 6.905 2.4925 7.3025 3.52H8.705C9.095 2.4925 10.22 1.75 11.375 1.75C12.875 1.75 14 2.875 14 4.375C14 6.5425 11.645 8.68 8.075 11.9125Z"
          fill="#474747"
        />
      </svg>
      <svg
        v-show="isSaved === true"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 14.0125L6.9125 13.0225C3.05 9.52 0.5 7.21 0.5 4.375C0.5 2.065 2.315 0.25 4.625 0.25C5.93 0.25 7.1825 0.8575 8 1.8175C8.8175 0.8575 10.07 0.25 11.375 0.25C13.685 0.25 15.5 2.065 15.5 4.375C15.5 7.21 12.95 9.52 9.0875 13.03L8 14.0125Z"
          fill="#474747"
        />
      </svg>
      <Transition
        enter-active-class="animate__animated animate__fadeOutUp"
        @after-enter="animateSave = false"
      >
        <svg
          v-if="animateSave === true"
          class="gs-z-0 gs-opacity-0 gs-absolute gs-top-2 gs-left-3.5"
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 14.0125L6.9125 13.0225C3.05 9.52 0.5 7.21 0.5 4.375C0.5 2.065 2.315 0.25 4.625 0.25C5.93 0.25 7.1825 0.8575 8 1.8175C8.8175 0.8575 10.07 0.25 11.375 0.25C13.685 0.25 15.5 2.065 15.5 4.375C15.5 7.21 12.95 9.52 9.0875 13.03L8 14.0125Z"
            fill="#474747"
          />
        </svg>
      </Transition>
    </button>
    <a
      v-if="!hasApplied && apply"
      :href="apply"
      @click="registerGiveAnalyApplyEvent(id)"
      class="gs-inline-flex gs-items-center gs-h-11 gs-px-6 gs-py-1 gs-rounded-full primary-btn hover:gs-text-gray-100 hover:gs-bg-gradient-to-r hover:gs-from-orange-400 hover:gs-to-pink-400 hover:gs-shadow-md hover:gs-shadow-orange-400/50"
    >
      Apply
    </a>
    <a
      v-if="hasApplied"
      class="opacity-75 gs-inline-flex gs-items-center gs-h-11 gs-px-6 gs-py-1 gs-rounded-full gs-text-gray-100 gs-bg-black hover:gs-text-gray-100 hove:gs-bg-black"
      style="pointer-events: none;"
    >
      {{ applicationStatus == 'Rejected' ? 'Unsuccessful' : applicationStatus }}
    </a>
  </div>
</template>

<script>
import client from '../utils/http';
import * as urlHelper from '../utils/url';

export default {
  props: {
    id: { type: Number, default: null },
    apply: { type: String, default: null },
    openTab: { type: String, default: null },
    share: { type: String, default: null },
    save: { type: String, default: null },
    isSaved: { type: Boolean, default: false },
    hasApplied: { type: Boolean, default: false },
    applicationStatus: {
      type: String,
      default: null,
    },
  },
  emits: ['toggleSaved'],
  data() {
    return {
      showCopied: true,
      saveDisabled: false,
      animateSave: false,
    };
  },
  methods: {
    shareWithoutWWW() {
      let share = this.share.replace('www.', '');
      share = share.replace('WWW.', '');

      return share;
    },
    copyLink() {
      this.showCopied = false;

      this.registerGiveAnalyShareEvent(this.id);

      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.share);
      }
    },
    toggleSaveResult() {
      this.saveDisabled = true;
      this.animateSave = true;

      if (this.isSaved) {
        this.removeSavedResult();
      } else {
        this.saveResult();
      }
    },
    async saveResult() {
      try {
        await client.post(this.save, { id: this.id });
      } catch (error) {
        urlHelper.redirectToPortalSearch(`save_job=${this.id}`);
      }

      this.$emit('toggleSaved');
      document.body.dispatchEvent(new CustomEvent('updateSavedJobs', { detail: { udpate: 'add' } }));
      this.saveDisabled = false;
    },
    async removeSavedResult() {
      try {
        await client.delete(`${this.save}/${this.id}`);
      } catch (error) {
        urlHelper.redirectToPortalSearch(`remove_save_job=${this.id}`);
      }

      this.$emit('toggleSaved');
      document.body.dispatchEvent(new CustomEvent('updateSavedJobs', { detail: { udpate: 'remove' } }));
      this.saveDisabled = false;
    },
  },
};
</script>

<style scoped>

  .primary-btn {
    background-color: #0A2430;
    color: #F9F7F4;
  }

</style>
