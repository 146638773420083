<template>
  <div
      style="position:relative;"
    class="search-result gs-bg-beige-100 gs-flex gs-justify-between gs-p-6 hover:gs-bg-beige-200 hover:gs-cursor-pointer"
    :class="{
      'gs-bg-gradient-to-r gs-from-green-100 gs-to-cyan-100': isActive
    }"
  >
    <div
      :data-job-id="result.id"
      class="gs-flex-1 gs-flex gs-flex-col lg:gs-flex-row gs-gap-x-1 gs-gap-y-4"
    >
      <div
        v-if="result.company"
        class="gs-flex-none gs-mr-4 gs-w-14"
      >
        <img
          class="gs-h-14 gs-w-full gs-rounded-xl"
          :src="(result.logo===null)? result.company.logo : result.logo"
          :alt="result.company.name"
        >
      </div>
      <div>
        <h2 class="gs-text-2xl lg:gs-text-base gs-font-medium gs-text-twilight-900 result-title">
          {{ result.title }}
        </h2>
        <div class="gs-flex gs-justify-start gs-flex-wrap gs-gap-5 gs-mt-1 gs-text-sm gs-text-beige-400">
          <span
            v-if="result.company"
            class="gs-font-medium"
          >
            {{ result.company_name? result.company_name : result.company.name }}
          </span>
          <span>
            {{ result.location.name }}
          </span>
        </div>
        <div
          v-if="result.salary_display"
          class="gs-flex gs-justify-start gs-flex-wrap gs-gap-5 gs-mt-1 gs-text-sm gs-text-beige-400"
        >
          <span>
            {{ result.salary_display }}
          </span>
            <span v-if="result.job_type && result.job_type.name==='Part time'">
                {{ result.job_type.name }}
            </span>
        </div>
        <search-job-type-tag-list
          :tags="result.tags"
        />
      </div>
    </div>
      <div style="position: absolute;top:6px;left:6px;" v-if="result.company.id!==1 && result.meta.has_applied">
          <button v-tooltip="{ content: 'Role not managed by Give a Grad a Go.<br/>Please contact the company directly for application updates.', html: true }">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20px" height="20px"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" fill="#000" /></svg>
          </button>
      </div>
    <search-result-actions
      :id="result.id"
      :save="result.links.save"
      :is-saved="result.meta.is_saved"
      :has-applied="result.meta.has_applied"
      :application-status="result.meta.application_status"
      @toggle-saved="$emit('toggleSaved')"
    />
  </div>
</template>

<script>
import SearchJobTypeTagList from './SearchJobTypeTagList.vue';
import SearchResultActions from './SearchResultActions.vue';
import { getAssetUrl } from '../utils/asset.js';

export default {
  components: {
    SearchJobTypeTagList,
    SearchResultActions,
  },
  props: {
    result: { type: Object, default: null },
    isActive: { type: Boolean, default: false },
  },
  emits: ['toggleSaved'],
  methods: {
    asset($url) {
      return getAssetUrl($url);
    },
  },
};
</script>

<style scoped>
  .search-result {margin-bottom: 0.1rem;}
</style>
