function getUrlState(url) {
  const state = {
    table: {},
  };

  url.searchParams.forEach((value, key) => {
    state.table[key] = value;
  });

  return state;
}

export function current() {
  return new URL(window.location);
}

export function replaceUrl(url) {
  window.history.replaceState(getUrlState(url), '', url.toString());
}

export function pushUrl(url) {
  window.history.pushState(getUrlState(url), '', url.toString());
}

export function getAllIds(url, key) {
  return url.searchParams
    .getAll(key)
    .map((value) => parseInt(value));
}

export function getAllStrings(url, key) {
  return url.searchParams
      .getAll(key)
      .map((value) => value);
}

export function redirectToPortalSearch(extraParamString = null) {
  const url = current();

  const urlto = `${process.env.PORTAL_DOMAIN}/candidate/search-jobs?${url.searchParams.toString()}${extraParamString}`;
  console.log('extraParamString');
  console.log(extraParamString);
  console.log(urlto);
  window.location = urlto;
}
