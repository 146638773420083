<template>
  <div v-if="image">
    <a
      :href="link"
      target="_blank"
    >
      <img
        :src="image"
        class="gs-w-full"
      >
    </a>
  </div>
</template>

<script>
import { store as midPageUnitStore } from '../stores/mid-page-unit';

export default {
  props: {
    position: { type: String, default: 'primary' },
    size: { type: String, default: '720x270' },
  },
  computed: {
    unit() {
      return midPageUnitStore.data.filter((unit) => unit.position === this.position)[0];
    },
    image() {
      if (this.unit && this.unit.hasOwnProperty(`image_${this.size}`)) {
        return this.unit[`image_${this.size}`];
      }

      return '';
    },
    link() {
      return this.unit.link ?? '';
    },
  },
};
</script>
