<template>
  <div class="gs-static lg:gs-relative">
      <Popper class="gs-h-16 gs-w-full lg:gs-h-full lg:gs-w-fit gs-py-2" placement="bottom" :locked="true">
          <button
              class="gs-inline-flex gs-items-center gs-justify-between gs-h-14 gs-w-full gs-px-4 gs-py-2 gs-rounded-full gs-border gs-border-beige-500 hover:gs-border-stone-500 lg:gs-text-sm lg:gs-h-full lg:gs-w-fit"
              :class="{
                'gs-border-stone-500': open,
                'gs-bg-blueslate gs-border-blueslate gs-text-white hover:gs-bg-blueslate-light': active
              }"
          >
              <slot />
              <font-awesome-icon
                  class="gs-ml-2 gs-text-xs"
                  :icon="['fas', 'chevron-down']"
              />
          </button>
          <template #content>
              <div class="gs-p-5 gs-w-full gs-rounded-md gs-shadow-inner gs-border gs-border-beige-500 gs-bg-beige-200 lg:gs-bg-white lg:gs-shadow-none lg:gs-w-96 lg:gs-drop-shadow-md">
                  <div style="max-height: 300px;overflow-y: auto;">
                      <slot name="options" />
                  </div>
                  <hr class="gs-mt-7 gs-border-t gs-border-beige-500">
                  <div class="gs-mt-5 gs-flex gs-justify-between gs-text-sm">
                      <button
                          class="hover:gs-text-twilight-900"
                          @click="reset()"
                      >
                          Reset
                      </button>
                      <primary-button
                          @click="save()"
                      >
                          Save
                      </primary-button>
                  </div>
              </div>
          </template>
      </Popper>
  </div>
</template>

<script>
import PrimaryButton from './PrimaryButton.vue';

export default {
  components: {
    PrimaryButton,
  },
  props: {
    active: { type: Boolean, default: false },
  },
  emits: ['save', 'reset', 'open', 'close'],
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.closeIfOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeIfOutsideClick);
  },
  methods: {
    toggle() {
      this.open = !this.open;
      this.$emit(this.open ? 'open' : 'close');
    },
    save() {
      this.open = false;
      this.$emit('save');
    },
    reset() {
      this.open = false;
      this.$emit('reset');
    },
    closeIfOutsideClick(e) {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
    },
  },
};
</script>

<style>
:root {
    --popper-theme-background-color: transparent;
    --popper-theme-background-color-hover: transparent;
    --popper-theme-text-color: rgb(31, 41, 55);
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 0px;
    --popper-theme-padding: 0;
    --popper-theme-box-shadow: none;
}

@media(max-width: 1024px) {

    .popper {
        /* We do this to prevent popper activating on mobile where we just list instead */
        position: relative !important;
        transform: none !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}
</style>