<template>
  <div class="gs-flex gs-flex-wrap gs-justify-start gs-gap-2 gs-mt-4">
    <button
      v-for="tag in showable"
      :key="tag.id"
      disabled
      class="job-tag gs-z-10 gs-border gs-rounded-full gs-text-beige-600 gs-text-xs gs-font-medium gs-mr-2 gs-px-2 gs-py-1"
    >
      # {{ tag.name }}
    </button>
      <button
          v-for="tag in hidden"
          v-show="hiddenCount && showHidden"
          :key="tag.id"
          disabled
          class="job-tag gs-z-10 gs-border gs-rounded-full gs-text-beige-600 gs-text-xs gs-font-medium gs-mr-2 gs-px-2 gs-py-1"
      >
          # {{ tag.name }}
      </button>

    <button
      v-show="hiddenCount && !showHidden"
      class="job-tag gs-z-10 gs-border gs-rounded-full gs-text-beige-600 gs-text-xs gs-font-medium gs-mr-2 gs-px-2 gs-py-1"
      @click.stop="showHidden = !showHidden"
    >
      # +{{ hiddenCount }}
    </button>
  </div>
</template>

<script>
import { store as detailStore } from '../stores/detail';

export default {
  props: {
    show: {
      type: Number,
      default: 2,
    },
    tags: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showHidden: false,
    };
  },
  computed: {
    hiddenCount() {
      const count = this.filterable.length - this.show;
      return count < 0 ? 0 : count;
    },
    hidden() {
      return this.filterable.slice(this.show);
    },
    showable() {
      return this.filterable.slice(0, this.show);
    },
    filterable() {
      return this.tags;
    },
  },
  watch: {
    tags() {
      this.showHidden = false;
    },
  },
};
</script>

<style scoped>

  .job-tag {border-color: #A1A19F;}

  .result-detail .job-tag {
    border: none;
    padding: 0.25rem 0;
  }

</style>
