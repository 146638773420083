<template>
  <div class="gs-text-stone-600 gs-flex gs-justify-center gs-space-x-6">
    <button
      class="gs-inline-flex gs-items-center gs-justify-center gs-w-14 gs-h-14 gs-rounded-full gs-border gs-border-beige-500 disabled:gs-border-beige-500 disabled:gs-text-stone-300 pagination-arrow"
      :disabled="! prev.url"
      @click="$emit('changePage', getPageFromUrl(prev.url))"
    >
      <svg
        class="arrow-left"
        xmlns="http://www.w3.org/2000/svg"
        width="13.333"
        height="6.667"
        viewBox="0 0 13.333 6.667"
      >
        <path
          id="Vector"
          d="M10.008,2.5H0V4.167H10.008v2.5l3.325-3.333L10.008,0Z"
          transform="translate(13.333 6.667) rotate(-180)"
        />
      </svg>
    </button>
    <div class="gs-inline-flex gs-items-center gs-justify-center lg:gs-hidden ">
      {{ currentPage }} / {{ lastPage }}
    </div>
    <div class="gs-hidden lg:gs-inline-flex lg:gs-items-center lg:gs-justify-center">
      <button
        v-for="page in pages"
        :key="page.url"
        :disabled="! page.url"
        class="gs-text-beige-400 gs-p-1"
        :class="{
          'gs-text-granite-500 gs-font-medium': page.active
        }"
        @click="$emit('changePage', getPageFromUrl(page.url))"
      >
        {{ page.label }}
      </button>
    </div>
    <button
      class="gs-inline-flex gs-items-center gs-justify-center gs-w-14 gs-h-14 gs-rounded-full gs-border gs-border-beige-500 disabled:gs-border-beige-500 disabled:gs-text-stone-300 pagination-arrow"
      :disabled="! next.url"
      @click="$emit('changePage', getPageFromUrl(next.url))"
    >
      <svg
        class="arrow-right"
        xmlns="http://www.w3.org/2000/svg"
        width="13.333"
        height="6.667"
        viewBox="0 0 13.333 6.667"
      >
        <path
          id="Vector"
          d="M10.008-2.5H0V-4.167H10.008v-2.5l3.325,3.333L10.008,0Z"
          transform="translate(0 6.667)"
        />
      </svg>
    </button>
  </div>
</template>

<script>

export default {
  props: {
    links: { type: Array, default: () => [] },
    currentPage: { type: Number, default: 1 },
    lastPage: { type: Number, default: 1 },
    facets: { type: Object, default: null },
  },
  emits: ['changePage'],
  computed: {
    next() {
      return this.links.slice(-1).pop();
    },
    prev() {
      return this.links.slice(0, 1).pop();
    },
    pages() {
      return this.links
        .slice(1)
        .slice(0, -1);
    },
  },
  methods: {
    getPageFromUrl(urlStr) {
      const bits = urlStr ? urlStr.split('=') : null;

      if (bits) {
        return bits[1];
      }

      return bits;
    },
  },
};
</script>

<style>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>

<style scoped>

  .pagination-arrow:disabled .arrow-left path,
  .pagination-arrow:disabled .arrow-right path {
    fill: #DCD9D3;
  }

  .pagination-arrow .arrow-left path,
  .pagination-arrow .arrow-right path {
    fill: #474747;
  }

</style>
