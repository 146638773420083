<template>
  <div v-if="job == null">
    <apply-form-header-template />
  </div>

  <div v-if="job">
    <div class="gs-mt-14 gs-max-h-[calc(100vh_-_2rem)]">
      <div class="gs-bg-beige-100 apply-form-header gs-p-6 gs-rounded-md">
        <div class="gs-flex gs-justify-between">
          <img
            v-if="job.company"
            class="gs-h-14 gs-w-14 gs-rounded-xl"
            :src="(job.logo===null)? job.company.logo : job.logo"
            :alt="job.company.name"
          >
          <primary-button
            id="quick-view-role"
            class="gs-text-sm"
            @click="toggleQuickViewRole"
          >
            Quick view role
          </primary-button>
        </div>

        <div class="gs-mt-5">
          <h2 class="gs-text-2xl gs-text-twilight-900">
            {{ job.title }}
          </h2>
          <div class="gs-flex gs-justify-start gs-space-x-5 gs-mt-2 gs-text-beige-400">
            <span v-if="job.location">
              {{ job.location.name }}<template v-if="job.company.remote_status"> ({{ job.company.remote_status }})</template>
            </span>
            <span v-if="job.salary_display">
              {{ job.salary_display }}
            </span>
            <span v-if="job.job_type && job.job_type.name">
              {{ job.job_type.name }}
            </span>
            <span v-if="job.published_at_human">
                <template v-if="job.published_at_human === 'Today' || job.published_at_human === 'Yesterday'">
                    Posted {{ job.published_at_human.toLowerCase() }}
                </template>
                <template v-else>
                    {{ job.published_at_human }}
                </template>
            </span>
          </div>
          <search-job-type-tag-list
            :tags="job.tags"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="isShowingQuickView"
    class="quick-view"
  >
    <font-awesome-icon
      class="close-quick-view"
      :icon="['fas', 'xmark']"
      @click="toggleQuickViewRole"
    />
    <div class="quick-view-inner">
      <search-result-detail
        v-if="job"
        :result="job"
        :is-quick-view="true"
      />
    </div>
  </div>
</template>

<script>
import client from '../utils/http';
import { getAssetUrl } from '../utils/asset.js';
import SearchJobTypeTagList from './SearchJobTypeTagList.vue';
import SearchResultActions from './SearchResultActions.vue';
import ApplyFormHeaderTemplate from './ApplyFormHeaderTemplate.vue';
import SearchResultDetail from './SearchResultDetail.vue';
import PrimaryButton from './PrimaryButton.vue';

export default {
  components: {
    SearchJobTypeTagList,
    SearchResultActions,
    ApplyFormHeaderTemplate,
    SearchResultDetail,
    PrimaryButton,
  },
  props: {

  },
  emits: ['toggleSaved'],
  data() {
    return {
      job: { type: Object, default: null },
      isShowingQuickView: { type: Boolean, default: false },
    };
  },
  watch: {
    // result() {
    //   const body = this.$el.querySelector('.gs-detail-body');

    //   body.scrollTo({
    //     top: 0,
    //     left: 0,
    //   });
    // },
  },
  beforeMount() {
    this.job = null;
    this.isShowingQuickView = false;
  },
  mounted() {
    this.fetchJob();
  },
  methods: {
    asset($url) {
      return getAssetUrl($url);
    },
    fetchJob() {
      const jobId = document.getElementById('gagago-apply-form-header').getAttribute('job-id');
      if (jobId) {
        client
          .get(`/jobs/${jobId}`, { params: null })
          .then((response) => {
            this.job = response.data.data;
          });
      }
    },
    toggleQuickViewRole() {
      this.isShowingQuickView = !this.isShowingQuickView;
    },
  },
};
</script>

  <style scoped>

    .quick-view {
      width: 100%;
      top: 0px;
      right: 0px;
      background-color: #F9F6F2;
      z-index: 5;
    }

    @media (min-width: 992px) {
      .quick-view {
        position: fixed;
        width: 50%;
      }
    }

    .close-quick-view{
      margin: 10px;
      float: right;
      cursor: pointer;
    }

    .quick-view-inner{
      margin: 60px;
      background-color: #FFFCFB;
      border-radius: 4px;
    }

  </style>
